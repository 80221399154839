import { ReactNode, useEffect, useRef, useState } from "react";
import './index.scss';

interface TabsProps {
    tabs: { id: number | string, label: string }[];
    children: ReactNode;
}

const Tabs = ({ tabs, children }: TabsProps) => {
    const [activeTab, setActiveTab] = useState<number | string | null>(null);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (tabs?.length && activeTab === null) {
            setActiveTab(tabs[0].id);
        }
    }, [tabs]);

    useEffect(() => {
        if (ref.current) {
            const visibleTab = ref.current.querySelector<HTMLDivElement>(`div[tab-index="${activeTab}"]`);
            if (visibleTab) {
                visibleTab.style.setProperty('display', 'flex');
            }
            const hiddenTabs = ref.current.querySelectorAll<HTMLDivElement>(`div[tab-index]:not([tab-index="${activeTab}"])`);
            if (hiddenTabs) {
                hiddenTabs.forEach(t => t.style.setProperty('display', 'none'));
            }

        }
    }, [ref, activeTab]);

    return (
        <div className="tabs">
            <div className="tabs-header">
                {tabs.map(t => (
                    <div
                        key={t.id}
                        className={t.id === activeTab ? 'active' : ''}
                        onClick={() => setActiveTab(t.id)}
                    >
                        {t.label}
                    </div>
                ))}
            </div>
            <div className="tabs-content" ref={ref}>
                {children}
            </div>
        </div>
    )
}

export default Tabs;