import { useCallback } from "react";
import Radio from "../Radio";
import InputWrapper, { FormPropsInterface } from "../InputWrapper";
import './index.scss';

interface RadioListItem<T> {
    key: T;
    label?: string;
}

interface RadioListProps<T> extends FormPropsInterface<T> {
    label?: string,
    items: RadioListItem<T>[],
    className?: string,
}

const RadioList = <T,>({
    value,
    items,
    disabled,
    onChange,
    ...rest
}: RadioListProps<T>) => {

    const handleChange = useCallback((v: T) => {
        if (disabled) return;
        onChange(v as any);
    }, [value, onChange, disabled]);

    return (
        <InputWrapper type="radio-list" {...rest}>
            {items.map(i => (
                <Radio
                    key={String(i.key)}
                    label={String(i.label ?? i.key)}
                    value={value === i.key}
                    onChange={() => handleChange(i.key)}
                    id={String(i.key)}
                />
            ))}
        </InputWrapper>
    )
}

export default RadioList;