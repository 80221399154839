import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { User } from "../models";
import AxiosInstance from "../services/Axios";
import { Role } from "../utils/enums";
import { useTranslation } from "react-i18next";

const DEFAULT_USER: User = { _id: "", connectedAt: 0 }

const login = (): void => {
    window.location.replace(`${process.env.REACT_APP_AUTH_PORTAL}?${new URLSearchParams({ redirect_uri: window.location.href })}`);
};

const noAccess = (): void => {
    window.location.replace(`${process.env.REACT_APP_AUTH_PORTAL ?? window.location.pathname}/no-access/${process.env.REACT_APP_APP_ID}`);
};

const user = async (): Promise<User> => {
    const response = await AxiosInstance.get<User>(`${process.env.REACT_APP_AUTH_API}/users/me`);
    return response.data;
};

interface RefreshProps {
    onToken: (t: string, r: Role) => void;
    onError: (c: number) => void;
    isShire?: boolean;
}

const Refresh = ({ onToken, onError, isShire }: RefreshProps) => {
    const [iframeRefresh, setIframeRefresh] = useState<number>(0);
    const qp = useMemo(() => new URLSearchParams({ r: String(iframeRefresh), audience: window.location.href, api_url: process.env.REACT_APP_AUTH_API ?? '', is_shire: String(!!isShire) }), [iframeRefresh]);

    const getTokenFromIframe = useCallback((event: any) => {
        if (event.data?.token && event.data?.tokenParsed?.role) {
            onToken(event.data.token, event.data.tokenParsed.role);
        } else if (event.data?.error) {
            onError(event.data.error);
        }
    }, [onToken, onError]);

    useEffect(() => {
        window.addEventListener('message', getTokenFromIframe);

        return () => {
            window.removeEventListener('message', getTokenFromIframe);
        }
    }, [getTokenFromIframe]);

    useEffect(() => {
        const interval = setInterval(() => {
            setIframeRefresh((i) => i + 1);
        }, 540000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    return <iframe
        src={`${process.env.REACT_APP_AUTH_PORTAL}/refresh.html?${qp}`}
        title="refresh" id="refresh-iframe"></iframe>;
}

type AuthContextProps = {
    allowPublic?: boolean;
    isShire?: boolean;
    children: ReactNode;
};

type AuthContextType = {
    currentUser: User;
    currentRole: Role | null;
    refreshUser: () => void;
    logout: () => void;
    setRefreshToken: (token: string) => void;
    token: string;
};

const AuthContext = createContext<AuthContextType>({
    currentUser: DEFAULT_USER,
    currentRole: null,
    refreshUser: () => null,
    logout: () => null,
    setRefreshToken: async () => null,
    token: ''
});

const AuthProvider = ({ allowPublic, children, isShire }: AuthContextProps) => {
    const [currentUser, setCurrentUser] = useState<User>(DEFAULT_USER);
    const [currentRole, setCurrentRole] = useState<Role | null>(null);
    const [token, setToken] = useState<string>('');
    const [isInit, setInit] = useState<boolean>(false);
    const { i18n } = useTranslation();

    const logout = useCallback(() => {
        sessionStorage.removeItem('sg-token');
        localStorage.removeItem('sg-token');
        setCurrentRole(null);
        setCurrentUser(DEFAULT_USER);

        if (!allowPublic) {
            if (isInit) {
                login();
            } else {
                login();
            }
            return;
        } else {
            setInit(true);
        }
    }, [isInit, allowPublic]);

    const getUser = useCallback(async () => {
        try {
            setCurrentUser(await user());
        } catch {
            logout();
        }
    }, [logout]);

    const onToken = useCallback(async (token: string, role: Role) => {
        sessionStorage.setItem('sg-token', token);
        setCurrentRole(role);
        setToken(token);

        if (!isInit) {
            await getUser();
            setInit(true);
        }
    }, [isInit, getUser]);

    const setRefreshToken = useCallback((token: string) => {
        localStorage.setItem('sg-token', token);
    }, []);

    useEffect(() => {
        const language = currentUser?.language ?? (['en', 'fr'].includes(navigator.language) ? navigator.language : 'en');

        if (i18n.language !== language) {
            i18n.changeLanguage(language)
        }
    }, [currentUser]);

    return (
        <AuthContext.Provider value={{
            currentUser,
            currentRole,
            refreshUser: getUser,
            setRefreshToken,
            logout,
            token,
        }}>
            {(!isInit || currentRole) && <Refresh onToken={onToken} onError={logout} isShire={isShire} />}
            {isInit ? children : null}
        </AuthContext.Provider>
    );
};


const useAuthContext = () => useContext<AuthContextType>(AuthContext);

export { AuthProvider, useAuthContext };
export default AuthContext;