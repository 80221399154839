import { ReactNode, useEffect, useRef, useState } from 'react';
import './index.scss';

interface PopoverProps {
    children: ReactNode;
    popover: ReactNode;
    disabled?: boolean;
    maxHeight?: string;
}

const Popover = ({ children, popover, disabled, maxHeight }: PopoverProps) => {
    const [isVisible, setVisible] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);


    useEffect(() => {
        if (ref.current) {
            const bound = ref.current.getBoundingClientRect();
            if (bound.top + bound.height > window.innerHeight) {
                ref.current.style.bottom = '0';
                ref.current.style.top = 'auto';
            } else {
                ref.current.style.top = '100%';
                ref.current.style.bottom = 'auto';
            }
            if (bound.left + bound.width > window.innerWidth) {
                ref.current.style.right = '0';
                ref.current.style.left = 'auto';
            } else {
                ref.current.style.left = '0';
                ref.current.style.right = 'auto';
            }
        }
    }, [isVisible, ref]);

    return (
        <div
            className={`popover ${isVisible ? 'popover-visible' : ''}`}
            onMouseLeave={() => setVisible(false)}
        >
            <div
                className="popover-children"
                onClick={() => !disabled ? setVisible(!isVisible) : null}
                onMouseEnter={() => setVisible(true)}
            >
                {children}
            </div>
            {isVisible && (
                <div
                    className="popover-content"
                    style={maxHeight ? { maxHeight } : undefined}
                    ref={ref}
                >
                    {popover}
                </div>
            )}
        </div>
    )
}

export default Popover;