import { Fragment } from 'react';
import { BrushIcon } from '../../icons';
import Dropdown from '../../ui/Dropdown';
import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import './index.scss';

interface ColorPickerProps extends FormPropsInterface<string> {
    colors: string[];
}

const ColorPicker = ({
    value,
    colors,
    onChange,
    disabled,
    ...rest
}: ColorPickerProps) => (
    <InputWrapper type="color-picker" {...rest}>
        <Dropdown
            closeOnClick
            dropdown={(
                <Fragment>
                    {colors.map(c => (
                        <div
                            key={c}
                            style={{ backgroundColor: c }}
                            onClick={() => onChange(c)}
                        />
                    ))}
                </Fragment>
            )}
            disabled={disabled}
        >
            <div style={{ backgroundColor: value ?? '' }}>
                <BrushIcon />
            </div>
        </Dropdown>
    </InputWrapper>
);

export default ColorPicker;