import { t } from 'i18next';
import { useState } from 'react';
import { SimpleList } from '..';
import { Modal } from '../../ui';
import './index.scss';
import { SimpleListSchema } from '../SimpleList';

interface EntitySearchProps<T> {
    visible: boolean;
    endpoint: string;
    primaryKey: keyof T;
    schema: SimpleListSchema<T>;
    onSubmit: (e: T[]) => void;
    onClose: () => void;
}

const EntitySearch = <T,>({ visible, endpoint, schema, onSubmit, onClose, primaryKey }: EntitySearchProps<T>) => {
    const [selected, setSelected] = useState<T[]>([]);

    return !visible
        ? null
        : (
            <Modal
                header={t("actions:search")}
                className="entity-search"
                onClose={onClose}
                onSubmit={() => onSubmit(selected)}
            >
                <SimpleList<T>
                    endpoint={endpoint}
                    primaryKey={primaryKey}
                    schema={schema}
                    actions={{ selectMultiple: setSelected }}
                    withSearch
                />
            </Modal>
        )
}

export default EntitySearch;