import { ReactNode } from 'react';
import './index.scss';
import { CloseIcon } from '../../icons';

interface ModalRightProps {
    className?: string;
    children?: ReactNode;
    onClose?: () => void;
}


const ModalRight = ({ className, children, onClose }: ModalRightProps) => {

    return (
        <div className={`modal-right ${className ?? ''}`}>
            {onClose && <CloseIcon className="modal-right-close-icon" onClick={onClose} />}
            {children}
        </div>
    )
}

export default ModalRight;