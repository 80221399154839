import { ReactNode, useEffect, useState } from 'react';
import { DownIcon, UpIcon } from '../../icons';
import './index.scss';

interface CollapseProps {
    title: ReactNode;
    subTitle?: ReactNode;
    opened?: boolean;
    onOpen?: () => void;
    closeOnClick?: boolean;
    withIcon?: boolean;
    children: ReactNode;
    className?: string;
}

const Collapse = ({
    title,
    subTitle,
    withIcon = true,
    opened = false,
    onOpen,
    children,
    className
}: CollapseProps) => {
    const [isOpened, setOpened] = useState<boolean>(opened);
    const [isInit, setInit] = useState<boolean>(opened);

    useEffect(() => {
        if (isOpened && !isInit) {
            setInit(true);
        }
        if (onOpen && isOpened) {
            onOpen();
        }
    }, [isOpened]);

    useEffect(() => {
        setOpened(opened);
    }, [opened]);

    useEffect(() => {
        setInit(false);
    }, [children, title]);

    return (
        <div className="collapse">
            <div className="collapse-header" onClick={() => setOpened(!isOpened)}>
                <div>
                    <span className="collapse-title">{title}</span>
                    <span className="collapse-subtitle">{subTitle}</span>
                </div>
                {withIcon && isOpened && <UpIcon />}
                {withIcon && !isOpened && <DownIcon />}
            </div>
            <div className={`collapse-content ${isOpened ? 'opened' : ''} ${className ?? ''}`}>
                {(isInit || isOpened) ? children : null}
            </div>
        </div>

    )
}

export default Collapse;