import { TextArea } from "..";
import { Modal } from "../../ui";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import './index.scss';

interface CommentModalProps {
    onClose: () => void;
    onSumbit: (comment?: string) => void;
}

const CommentModal = ({ onClose, onSumbit }: CommentModalProps) => {
    const { t } = useTranslation();
    const [comment, setComment] = useState<string>();

    return (
        <Modal
            type="small"
            header={t('actions:comment')}
            className="comment-modal"
            onClose={onClose}
            onSubmit={() => onSumbit(comment)}
        >
            <TextArea
                small
                id="validationComment"
                value={comment}
                onChange={(c) => setComment(c)}
                rows={5}
            />
        </Modal>
    )
}

export default CommentModal;