import { LngLatBounds } from "react-map-gl";
export const getMarkersBoundingBox = <
    T extends
        | { lng: number; lat: number }
        | { longitude: number; latitude: number }
>(
    entities: T[]
): [[number, number], [number, number]] => {
    const lngs = entities.map((e) =>
        Object.hasOwn(e, "longitude")
            ? (e as { longitude: number }).longitude
            : (e as { lng: number }).lng
    );
    const lats = entities.map((e) =>
        Object.hasOwn(e, "latitude")
            ? (e as { latitude: number }).latitude
            : (e as { lat: number }).lat
    );

    return [
        [Math.min(...lngs), Math.min(...lats)],
        [Math.max(...lngs), Math.max(...lats)],
    ];
};

export const areAllInside = <T extends { lng: number; lat: number }>(
    entities: T[],
    bounds: LngLatBounds
): boolean =>
    !entities.some(
        (e) =>
            e.lng < bounds?.getNorthWest().lng ||
            e.lng > bounds?.getSouthEast().lng ||
            e.lat > bounds?.getNorthWest().lat ||
            e.lat < bounds?.getSouthEast().lat
    );

export const areAllOutside = <T extends { lng: number; lat: number }>(
    entities: T[],
    bounds: LngLatBounds
): boolean =>
    !entities.some(
        (e) =>
            e.lng >= bounds?.getNorthWest().lng &&
            e.lng <= bounds?.getSouthEast().lng &&
            e.lat <= bounds?.getNorthWest().lat &&
            e.lat >= bounds?.getSouthEast().lat
    );
