import { FormPropsInterface } from '../InputWrapper';
import './index.scss';

const Radio = ({
    id,
    value,
    label,
    className,
    disabled,
    onChange,
}: FormPropsInterface<boolean>) => (
    <div
        className={`radio ${value ? 'checked' : ''} ${className ?? ''}`}
        onClick={typeof onChange === 'function' && !disabled ? (e) => { e.stopPropagation(); onChange(!value); } : undefined}
    >
        <input
            type="radio"
            checked={!!value}
            onChange={typeof onChange === 'function' && !disabled ? (e) => { e.stopPropagation(); onChange(!value); } : undefined}
            id={id}
            name={id}
        />
        <div className="mark" />
        {!!label && <label className={disabled ? 'disabled' : ''}>{label}</label>}
    </div>
);

export default Radio;