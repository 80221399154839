import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import { ReactNode } from 'react';

export interface TextFieldProps extends FormPropsInterface<string> {
    type?: string,
    maxLength?: number,
    placeholder?: string,
    icon?: ReactNode;
    onBlur?: (value: string) => void,
    onFocus?: (value: string) => void,
    onBlurValidationError?: (value: string) => void,
    onBlurValidationWarning?: (value: string) => void,
    autoComplete?: boolean
}

const TextField = ({
    id,
    type,
    value,
    maxLength,
    placeholder,
    disabled,
    onBlur,
    onFocus,
    onChange,
    onBlurValidationError,
    onBlurValidationWarning,
    autoComplete,
    ...rest
}: TextFieldProps) => {
    return (
        <InputWrapper id={id} type="text" {...rest}>
            <input
                autoComplete={autoComplete ? "on" : "off"}
                id={id}
                type={type ?? 'text'}
                readOnly={!!disabled}
                onBlur={typeof onBlur === 'function' ? (e) => onBlur(e.target.value) : undefined}
                onFocus={typeof onFocus === 'function' ? (e) => onFocus(e.target.value) : undefined}
                onChange={typeof onChange === 'function' ? (e) => onChange(e.target.value) : undefined}
                value={value ?? ''}
                placeholder={placeholder ?? ''}
                maxLength={maxLength ? maxLength : undefined}
            />
        </InputWrapper>
    );
}

export default TextField;