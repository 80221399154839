import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../context';
import { NavigationItem } from '../Nav';
import './index.scss';

export interface SubNavProps {
    root: string;
    navigation: NavigationItem[];
}

const SubNav = ({ root, navigation }: SubNavProps) => {
    const { currentRole } = useAuthContext();
    const { t } = useTranslation();
    const location = useLocation();

    const navigationItems = useMemo(() => navigation
        .filter(item => !item.roles || (currentRole && item.roles.includes(currentRole)))
        .map(item => (
            <Link
                key={item.key}
                to={item.path ? `/${root}/${item.path}` : `/${root}`}
                className={`sub-nav-item ${(!item.path && location.pathname === `/${root}`) || (item.path && location.pathname.startsWith(`/${root}/${item.path}`)) ? 'active' : ''}`}
            >
                {item.icon}
                <span>{t('path:' + item.key)}</span>
            </Link>
        )), [navigation, currentRole, location.pathname, root]);

    return (
        <div className="sub-nav">
            {navigationItems}
        </div>
    )
}

export default SubNav;