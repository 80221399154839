// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=color-picker] .dropdown > .dropdown-content {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.input[input-type=color-picker] .dropdown > .dropdown-content > * {
  content: "";
  flex-shrink: 1;
  width: 25%;
  height: 16px;
  cursor: pointer;
}
.input[input-type=color-picker] .dropdown > .dropdown-children {
  padding: 0 !important;
}
.input[input-type=color-picker] .dropdown > .dropdown-children > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.input[input-type=color-picker] .dropdown > .dropdown-children > div > svg {
  width: 24px;
  height: 24px;
  fill: var(--theme-input-font-color);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/ColorPicker/index.scss"],"names":[],"mappings":"AAEQ;EACI,UAAA;EACA,aAAA;EACA,eAAA;AADZ;AAGY;EACI,WAAA;EACA,cAAA;EACA,UAAA;EACA,YAAA;EACA,eAAA;AADhB;AAIQ;EACI,qBAAA;AAFZ;AAIY;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAFhB;AAIgB;EACI,WAAA;EACA,YAAA;EACA,mCAAA;AAFpB","sourcesContent":[".input[input-type=\"color-picker\"] {\n    & .dropdown {\n        & > .dropdown-content {\n            padding: 0;\n            display: flex;\n            flex-wrap: wrap;\n\n            & > * {\n                content: \"\";\n                flex-shrink: 1;\n                width: 25%;\n                height: 16px;\n                cursor: pointer;\n            }\n        }\n        & > .dropdown-children {\n            padding: 0 !important;\n\n            & > div {\n                display: flex;\n                align-items: center;\n                justify-content: center;\n                width: 100%;\n                height: 100%;\n                border-radius: 4px;\n\n                & > svg {\n                    width: 24px;\n                    height: 24px;\n                    fill: var(--theme-input-font-color);\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
