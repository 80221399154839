import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Application } from '../../models/application';
import { UserStatus } from '../../models/enums';
import { Subscription, UserSubscriptions } from '../../models/user-permission';
import { DataList } from '../../sg-react/data';
import { DataListFormProps, DataListSchema } from '../../sg-react/data/DataList';
import SimpleList, { SimpleListSchema } from '../../sg-react/data/SimpleList';
import { DatePicker, Select, TextField } from '../../sg-react/form';
import { useForm } from '../../sg-react/hooks';
import { ValidationRule } from '../../sg-react/hooks/useForm';
import { PaginatedResults } from '../../sg-react/models/pagination';
import AxiosInstance from '../../sg-react/services/Axios';
import { Group, Modal } from '../../sg-react/ui';
import { dateToLocaleString } from '../../sg-react/utils/date';
import { Role } from '../../sg-react/utils/enums';
import './index.scss';
import { TrashIcon } from '../../sg-react/icons';

const SUBSCRIPTION_VALIDATION = {
    application: [{ rule: ValidationRule.Required }],
    role: [{ rule: ValidationRule.Required }],
};

export interface SubscriptionFormProps {
    subscription: Partial<Subscription>;
    onSubmit: (s: Subscription) => void;
    onDelete?: (s: Subscription) => void;
    onClose: () => void;
    applications: Application[];
    isNew?: boolean;
}


const SubscriptionForm = ({ subscription, isNew, applications, onSubmit, onClose, onDelete }: SubscriptionFormProps) => {
    const { entity, validate, attachInput, setEntity } = useForm<Subscription>(subscription);
    const { t } = useTranslation();

    const handleSubmit = async (): Promise<void> => {
        if (validate(SUBSCRIPTION_VALIDATION)) {
            onSubmit(entity as Subscription);
        }
    }

    useEffect(() => {
        setEntity(subscription)
    }, [subscription]);

    return (
        <Modal
            type="small"
            className="subscription-form"
            header={
                !isNew
                    ? t('data:edit')
                    : t('data:new')
            }
            onClose={onClose}
            onSubmit={handleSubmit}
            actions={onDelete ? [{ key: 'delete', color: 'error', label: t('actions:delete'), icon: <TrashIcon />, onClick: () => onDelete(entity as Subscription) }] : []}
        >
            <Select small i18n="users" label disabled={!isNew} items={applications.map(a => ({ key: a._id, label: a.name }))} {...attachInput('application')} />
            <Select small i18n="users" label items={Object.values(Role).map(r => ({ key: r, label: r }))} {...attachInput('role')} />
            <DatePicker small i18n="users" label weekDayStart={1} {...attachInput('from')} />
            <DatePicker small i18n="users" label weekDayStart={1} {...attachInput('to')} />
        </Modal>
    )
}

const SUBSCRIPTION_SCHEMA: SimpleListSchema<Subscription> = {
    applicationPopulated: {
        label: 'Application',
        display: (e) => e.applicationPopulated?.name ?? ''
    },
    role: {
        label: 'Role',
    },
    from: {
        label: 'From',
        display: (e) => e.from ? dateToLocaleString(e.from) : ''
    },
    to: {
        label: 'To',
        display: (e) => e.to ? dateToLocaleString(e.to) : ''
    },
};

const UserForm = ({ entity, onChange, attachInput }: DataListFormProps<UserSubscriptions>) => {
    const { t } = useTranslation();
    const [subscriptionToEdit, setSubscriptionToEdit] = useState<Partial<Subscription> | null>();
    const [applications, setApplications] = useState<Application[]>([]);

    const getApplications = useCallback(async () => {
        try {
            const response = await AxiosInstance.get<PaginatedResults<Application>>('/applications', { params: { perPage: -1 } });
            setApplications(response.data.data);
        } catch { }
    }, []);

    const handleSubscriptionSubmit = useCallback((subscription: Subscription) => {
        subscription.applicationPopulated = applications.find(a => a._id === subscription.application);
        if (!entity.subscriptions?.length) {
            onChange('subscriptions', [subscription]);
            setSubscriptionToEdit(null);
            return;
        }

        if (subscription._id) {
            const index = entity.subscriptions?.findIndex(s => s._id === subscription._id);

            if (index >= 0) {
                const _subscriptions = [...entity.subscriptions];
                _subscriptions[index] = subscription;
                onChange('subscriptions', _subscriptions);
            }
        } else {
            onChange('subscriptions', [...entity.subscriptions, subscription]);
        }

        setSubscriptionToEdit(null);
    }, [applications, onChange, entity]);

    const handleSubscriptionDelete = useCallback((subscription: Subscription) => {
        onChange('subscriptions', entity.subscriptions?.filter(s => s._id !== subscription._id));
        setSubscriptionToEdit(null);
    }, [onChange, entity]);

    useEffect(() => {
        getApplications();
    }, []);

    return (
        <div id="user-form">
            <TextField inline small i18n="users" label {...attachInput('email')} />
            <Select inline small items={Object.values(Role)} i18n="users" label {...attachInput('role')} />
            {!!entity._id && <Select inline small items={Object.values(UserStatus)} i18n="users" label {...attachInput('status')} />}
            <Group label={t('users:subscriptions')}>
                <SimpleList<Subscription>
                    data={entity?.subscriptions ?? []}
                    primaryKey="_id"
                    schema={SUBSCRIPTION_SCHEMA}
                    actions={{
                        create: () => setSubscriptionToEdit({}),
                        click: (s) => setSubscriptionToEdit(s)
                    }}
                />
                {subscriptionToEdit && (
                    <SubscriptionForm
                        subscription={subscriptionToEdit}
                        isNew={!subscriptionToEdit.application}
                        onSubmit={handleSubscriptionSubmit}
                        applications={applications}
                        onClose={() => setSubscriptionToEdit(null)}
                        onDelete={subscriptionToEdit?._id ? handleSubscriptionDelete : undefined}
                    />
                )}
            </Group>
        </div>
    )
}

const LIST_SCHEMA: DataListSchema<UserSubscriptions> = {
    _id: {
        label: 'ID',
        width: '10%',
        options: {
            order: true,
        }
    },
    email: {
        label: 'Email',
        width: '20%',
        display: 'textinput',
        options: {
            order: true,
            search: true,
            edit: true,
        }
    },
    role: {
        label: 'Role',
        display: 'select',
        values: Object.values(Role) as Role[],
        width: '20%',
        options: {
            order: true,
            in: true,
            edit: true,
        }
    },
    status: {
        label: 'Status',
        display: 'select',
        values: Object.values(UserStatus) as UserStatus[],
        width: '20%',
        options: {
            order: true,
            in: true,
            edit: true,
        }
    },
}

const Users = () => {
    const { t } = useTranslation();

    return (
        <DataList<UserSubscriptions>
            title={t('users:title')}
            schema={LIST_SCHEMA}
            primaryKey="_id"
            endpoint="/users"
            validation={{
                email: [
                    { rule: ValidationRule.Required },
                    { rule: ValidationRule.Email },
                ],
                role: [{ rule: ValidationRule.Required }],
            }}
            actions={{ view: true }}
            createForm={UserForm}
            updateForm={UserForm}
        />
    )
}

export default Users;
