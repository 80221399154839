import { useTranslation } from "react-i18next";
import { Checkbox, TextArea, TextField } from "../../sg-react/form";
import logo from "../../sg-react/assets/images/logos/logo_official_1.png";
import { useForm } from "../../sg-react/hooks";
import { ValidationRule } from "../../sg-react/hooks/useForm";
import { Button, Card } from "../../sg-react/ui";
import screenshot_filters from "./assets/filters.png";
import screenshot_map from "./assets/map.png";
import screenshot_sg from "./assets/sg.png";
import './index.scss';

const VALIDATION = {
    email: [{ field: 'password', rule: ValidationRule.Email }],
};

interface SurveyAnswer {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    field: string;
    comment: string;
    agree: boolean;
    shareData: boolean;
    viewData: boolean;
}

const Survey = () => {
    const { entity, validate, attachInput } = useForm<SurveyAnswer>({});
    const { t } = useTranslation();

    return (
        <Card id="survey">
            <div id="survey-container">
                <div id="survey-left">
                    <div id="survey-title">
                        <div>L'annuaire industriel</div>
                        <div><span>par</span> SUPPLY-GRAPH<img src={logo} alt="" /></div>
                    </div>
                    <div>Aidez-nous à construire un annuaire regroupant tous les industriels français par secteur d’activité.</div>
                    <div>Accessible par tous les industriels<br />Gratuit pour tous les industriels<br /><br /></div>
                    <div>Vous êtes intéressés pour :</div>
                    <Checkbox {...attachInput('shareData')} label="Partager des données" />
                    <Checkbox {...attachInput('viewData')} label="Consulter des données" />
                    <div id="survey-form">
                        <div className="flex-row-equal-cols">
                            <TextField
                                small
                                {...attachInput('firstName')}
                                label="Prénom"
                                autoComplete
                            />
                            <TextField
                                small
                                {...attachInput('lastName')}
                                label="Nom"
                                autoComplete
                            />
                        </div>
                        <TextField
                            small
                            {...attachInput('company')}
                            label="Société"
                            autoComplete
                        />
                        <TextField
                            small
                            {...attachInput('field')}
                            label="Secteur d'activité"
                            autoComplete
                        />
                        <TextField
                            small
                            {...attachInput('email')}
                            label="Email professionnel"
                            autoComplete
                        />
                        <TextArea
                            small
                            {...attachInput('comment')}
                            label="Commentaire ou remarque"
                        />
                    </div>
                    <div id="survey-footer">
                        <Checkbox {...attachInput('agree')} label="En cochant cette case vous consentez à l'utilisation de vos information à des fins de prospections." />
                        <Button color="accent" label="Envoyer la réponse" />
                    </div>
                </div>
                <div id="survey-right">
                    <img id="filters" src={screenshot_filters} alt="Supply-Graph" />
                    <img id="map" src={screenshot_map} alt="Supply-Graph" />
                    <img id="sg" src={screenshot_sg} alt="Supply-Graph" />
                </div>
            </div>
        </Card>
    )
}

export default Survey;