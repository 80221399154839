// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.public-user-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2px;
}
.public-user-card > .bubble-head {
  margin-bottom: 8px;
}
.public-user-card > .public-user-card-name {
  font-size: 14px;
}
.public-user-card > .public-user-card-position {
  font-size: 13px;
}
.public-user-card > .public-user-card-contact {
  display: flex;
  align-items: center;
  gap: 8px;
}
.public-user-card > .public-user-card-contact svg {
  width: 24px;
  height: 24px;
  fill: var(--theme-color-opposite);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/PublicUserCard/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,QAAA;AACJ;AACI;EACI,kBAAA;AACR;AAEI;EACI,eAAA;AAAR;AAGI;EACI,eAAA;AADR;AAII;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AAFR;AAIQ;EACI,WAAA;EACA,YAAA;EACA,iCAAA;AAFZ","sourcesContent":[".public-user-card {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    gap: 2px;\n\n    & > .bubble-head {\n        margin-bottom: 8px;\n    }\n\n    & > .public-user-card-name {\n        font-size: 14px;\n    }\n\n    & > .public-user-card-position {\n        font-size: 13px;\n    }\n\n    & > .public-user-card-contact {\n        display: flex;\n        align-items: center;\n        gap: 8px;\n\n        & svg {\n            width: 24px;\n            height: 24px;\n            fill: var(--theme-color-opposite);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
