import { ReactNode, useEffect, useMemo, useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { CircleHelpIcon } from '../../icons';
import { Tooltip } from '../../ui';

interface InputMessagesProps {
    level: 'error' | 'warning',
    messages?: string[],
}

const InputMessages = ({ level, messages }: InputMessagesProps) => {
    const [messagesTranslated, setMessagesTranslated] = useState<string[]>()
    const { t } = useTranslation()

    useEffect(() => {
        setMessagesTranslated(messages?.length ? messages?.map(m => t('form:error.' + m)) : undefined)
    }, [messages]);

    return !messagesTranslated?.length
        ? null
        : <div className={`input-messages-${level}`}>{messagesTranslated.join('.\n')}</div>;
}

interface InputWrapperProps {
    id: string;
    type: string;
    className?: string;
    label?: string | boolean;
    icon?: React.ReactNode;
    tooltip?: string | boolean;
    i18n?: string;
    inline?: boolean;
    small?: boolean;
    errors?: string[];
    warnings?: string[];
    children: ReactNode;
}

export interface FormPropsInterface<T> extends Omit<InputWrapperProps, 'children' | 'type'> {
    disabled?: boolean;
    value?: T;
    onChange: (value?: T) => void;
}

const InputWrapper = ({
    id,
    type,
    label,
    icon,
    className,
    tooltip,
    i18n,
    inline,
    small,
    errors,
    warnings,
    children,
}: InputWrapperProps) => {
    const { t } = useTranslation();

    const labelComponent = useMemo(() => label
        ? (
            <label>
                {i18n ? t(`${i18n}:${label === true ? id : label}`) : label}
                {tooltip ? <Tooltip tooltip={i18n ? t(`${i18n}:${tooltip === true ? id + '_tooltip' : tooltip}`) : tooltip as string}><CircleHelpIcon /></Tooltip> : null}
            </label>
        ) : null
        , [i18n, label, tooltip, id]);

    return (
        <div
            className={`input ${className ?? ''} ${inline ? 'input-inline' : ''} ${small ? 'input-small' : ''} ${errors?.length ? 'input-error' : warnings?.length ? 'input-warning' : ''}`}
            input-type={type}
        >
            <div className="input-body">
                {labelComponent}
                <div className="input-field">
                    {icon && <div className="input-icon">{icon}</div>}
                    {children}
                </div>
            </div>
            {(!!errors?.length || !!warnings?.length) && (
                <div className="input-messages">
                    <InputMessages messages={errors} level="error" />
                    <InputMessages messages={warnings} level="warning" />
                </div>
            )}
        </div>
    )
}

export default InputWrapper;