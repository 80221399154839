// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail,
.detail-inline {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.detail > label,
.detail-inline > label {
  color: var(--theme-input-label-color);
}
.detail > div,
.detail-inline > div {
  font-size: 11px;
}
.detail.detail-inline,
.detail-inline.detail-inline {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
}
.detail.detail-inline > label,
.detail-inline.detail-inline > label {
  width: 30%;
  flex-shrink: 0;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Detail/index.scss"],"names":[],"mappings":"AAAA;;EAEI,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;AACI;;EACI,qCAAA;AAER;AACI;;EACI,eAAA;AAER;AACI;;EACI,aAAA;EACA,mBAAA;EACA,WAAA;EACA,QAAA;AAER;AAAQ;;EACI,UAAA;EACA,cAAA;EACA,iBAAA;AAGZ","sourcesContent":[".detail,\n.detail-inline {\n    margin-bottom: 12px;\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n\n    & > label {\n        color: var(--theme-input-label-color);\n    }\n\n    & > div {\n        font-size: 11px;\n    }\n\n    &.detail-inline {\n        display: flex;\n        flex-direction: row;\n        width: 100%;\n        gap: 8px;\n\n        & > label {\n            width: 30%;\n            flex-shrink: 0;\n            text-align: right;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
