import { Fragment, ReactNode, useCallback, useEffect, useState } from "react";
import './index.scss';
import Collapse from "../Collapse";

interface AccordionProps {
    items: {
        key: string;
        label: string;
        component: ReactNode;
    }[];
}

const Accordion = ({ items }: AccordionProps) => {
    const [active, setActive] = useState<string | null>(null);

    const Collapses = useCallback(({ active, setActive }: { active: string | null, setActive: (k: string) => void }) => (
        <Fragment>
            {items.map(i => (
                <Collapse
                    key={i.key}
                    title={i.label}
                    opened={i.key === active}
                    onOpen={() => setActive(i.key)}
                >
                    {i.component}
                </Collapse>

            ))}
        </Fragment>
    ), [items]);

    useEffect(() => {
        setActive(null);
    }, [items]);

    return (
        <div className="accordion">
            <Collapses active={active} setActive={setActive} />
        </div>
    )
}

export default Accordion;