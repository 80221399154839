import { ReactNode, useMemo } from "react";
import { DataListColumnWithWidth } from ".";
import { Select, TextField } from "../../form";
import { getStringValue } from "../../utils/format";

interface CellProps<T, U extends keyof T> {
    entity: T;
    id: U;
    column: DataListColumnWithWidth<T, U>;
    onChange: (value: any) => void;
    errors?: string[];
}

const Cell = <T, U extends keyof T>({ entity, id, column, onChange, errors }: CellProps<T, U>) => {
    const v = useMemo(() => {

        let v: ReactNode;
        let stringV = getStringValue(entity[id]);

        if (typeof column.display === 'function') {
            v = column.display(entity);
        } else if (column.options?.edit) {
            if (column.display === 'textinput') {
                v = (
                    <TextField
                        id={id as string}
                        onChange={onChange}
                        value={stringV}
                        errors={errors}
                    />
                )
            } else if ((column.display === 'select-multiple' || column.display === 'select') && column.values) {
                v = (
                    <Select
                        id={id as string}
                        value={Array.isArray(entity[id]) ? entity[id] as string[] : entity[id] as string}
                        multiple={column.display === 'select-multiple'}
                        items={column.values.map(v => ({ key: v as string, label: v as string })) ?? []}
                        onChange={onChange}
                        errors={errors}
                    />
                )
            }
        } else {
            v = <span>{stringV}</span>;
        }

        return v;
    }, [column, entity, errors]);

    return (
        <div
            className="data-list-table-cell"
            style={{ width: column.widthPx + 'px', minWidth: column.widthPx + 'px' }}
        >
            {v}
        </div>
    )
}

export default Cell;