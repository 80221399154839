import { Fragment, MouseEvent, ReactNode, useCallback, useRef } from 'react';
import './index.scss';

interface TooltipProps {
    children: ReactNode;
    tooltip?: string;
}

const Tooltip = ({ children, tooltip }: TooltipProps) => {
    const ref = useRef<HTMLDivElement | null>(null);

    const handleMouseMove = useCallback((event: MouseEvent<HTMLDivElement>) => {
        if (ref.current) {
            const left = event.clientX + 6;
            const top = event.clientY + 6;
            const box = ref.current.getBoundingClientRect();

            ref.current.style.visibility = 'visible';
            if (left + box.width > window.innerWidth) {
                ref.current.style.right = '0';
                ref.current.style.left = 'unset';
            } else {
                ref.current.style.right = 'unset';
                ref.current.style.left = left + 'px';
            }

            if (top + box.height > window.innerHeight) {
                ref.current.style.bottom = '0';
                ref.current.style.top = 'unset';
            } else {
                ref.current.style.bottom = 'unset';
                ref.current.style.top = top + 'px';
            }
        }
    }, []);

    const handleMouseLeave = useCallback(() => {
        if (ref.current) {
            ref.current.style.visibility = 'hidden';
        }
    }, []);

    return (
        <Fragment>
            <div className="tooltip"
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
            >
                {children}
                {!!tooltip && (
                    <div className="tooltip-tooltip" ref={ref}>
                        {tooltip}
                    </div>
                )}
            </div>
        </Fragment>
    )
}

export default Tooltip;