import { RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuthContext } from "../sg-react/context";
import { RouteError } from "../sg-react/ui";
import { Role } from "../sg-react/utils/enums";
import Private from "./Private";
import Public from "./Public";
import Access from "./access";
import Applications from "./applications";
import Login from "./login";
import NewPassword from "./new-password";
import Profile from "./profile";
import Recovery from "./recovery";
import Settings from "./settings";
import Surveys from "./surveys";
import Users from "./users";
import Survey from "./survey";

const getRoutesForRole = (role: Role): RouteObject[] => [
    {
        path: "/",
        element: <Private />,
        children: [
            {
                path: '/*',
                element: <RouteError />,
            },
            {
                path: 'settings',
                element: <Settings />,
            },
            {
                path: 'profile',
                element: <Profile />
            },
            ...(role && [Role.Admin, Role.SuperAdmin].includes(role) ? [
                {
                    path: 'users',
                    element: <Users />,
                },
                {
                    path: 'applications',
                    element: <Applications />,
                },
                {
                    path: 'surveys',
                    element: <Surveys />,
                }
            ] : []),
            {
                path: '/',
                element: <Access />
            },
        ],
    },
];

const publicRoutes = [
    {
        path: "/",
        element: <Public />,
        children: [
            {
                path: '/',
                element: <Login />,
            },
            {
                path: 'recovery',
                element: <Recovery />,
            },
            {
                path: 'confirm-subscription',
                element: <NewPassword />,
            },
            {
                path: 'confirm-recovery',
                element: <NewPassword />,
            },
            {
                path: 'survey/:_id?',
                element: <Survey />,
            },
            {
                path: '/*',
                element: <Login />,
            },
        ]
    },
];

const App = () => {
    const { currentRole } = useAuthContext();

    return currentRole
        ? <RouterProvider router={createBrowserRouter(getRoutesForRole(currentRole))} />
        : <RouterProvider router={createBrowserRouter(publicRoutes ?? [])} />
}

export default App;