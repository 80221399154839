import { EmailIcon, LinkedInIcon, PhoneIcon } from "../../icons";
import { UserPublic } from "../../models";
import BubbleHead from "../BubbleHead";
import Card from "../Card";
import './index.scss';

interface PublicUserCardProps {
    user: UserPublic;
}

const PublicUserCard = ({ user }: PublicUserCardProps) => {

    return (
        <Card className="public-user-card">
            <BubbleHead image={user.avatar} />
            <div className="public-user-card-name">{`${user.firstName} ${user.lastName}`}</div>
            <div className="public-user-card-position">{[user.position, user.field].filter(v => !!v).join(', ')}</div>
            <div className="public-user-card-contact">
                <a href={`mailto:${user.email}`}><EmailIcon /></a>
                {!!user.linkedIn && <a href={user.linkedIn} target="_blank" rel="noreferrer"><LinkedInIcon /></a>}
            </div>
        </Card>
    )
}

export default PublicUserCard;