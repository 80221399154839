import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUiContext } from "../../context";
import { Checkbox, Toggle } from "../../form";
import { useDebounce, useRequest } from "../../hooks";
import { CloseIcon, PlusIcon } from "../../icons";
import { PaginatedResults, Pagination } from "../../models/pagination";
import AxiosInstance from "../../services/Axios";
import { Button, Nothing } from "../../ui";
import { getStringValue } from "../../utils/format";
import { getPrimaryKeysAsString, isType, primaryKeysEquals, toggleInArray } from "../../utils/objects";
import PaginationComponent from "../Pagination";
import "./index.scss";

export type SimpleListSchema<T> = {
    [U in keyof T]?: {
        label: string;
        display?: 'toggle' | 'checkbox' | ((e: T) => ReactNode);
    }
}

export interface ListProps<T> {
    schema: SimpleListSchema<T>;
    primaryKey: keyof T | (keyof T)[];
    data?: T[];
    endpoint?: string;
    actions?: {
        create?: () => void;
        add?: () => void;
        delete?: (e: T) => Promise<void | boolean> | void | boolean;
        deleteMultiple?: (e: T[]) => Promise<void | boolean> | void | boolean;
        click?: (e: T) => Promise<void | boolean> | void | boolean;
        change?: (e: T) => Promise<void | boolean> | void | boolean;
        select?: (e: T | null) => void;
        selectMultiple?: (e: T[]) => void;
    },
    withSearch?: boolean;
}

const SimpleList = <T,>({
    schema,
    primaryKey,
    endpoint,
    data: dataFromProps,
    actions,
    withSearch,
}: ListProps<T>) => {
    const { t } = useTranslation();
    const request = useRequest();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<T[]>([]);
    const [selected, setSelected] = useState<T[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [search, setSearch] = useState<string>('');
    useDebounce(() => get(), 800, [search]);

    const get = useCallback(async (_pagination?: Partial<Pagination>) => {
        if (!endpoint) {
            setData(dataFromProps ?? []);
            return;
        }
        if (isLoading) return;

        setLoading(true);

        const params = {
            ...(_pagination ?? pagination),
            search: !!search ? search : undefined
        }
        request.get<PaginatedResults<T> | T[]>(endpoint, { params, i18n: true, errorMessage: 'error.server_error' })
            .then((data) => {
                if (isType<PaginatedResults<T>>(data, (o) => o.hasOwnProperty('pagination'))) {
                    setData(data.data);
                    setPagination(data.pagination);
                } else {
                    setData(data);
                }
            })
            .catch(() => null)
            .finally(() => setLoading(false));
    }, [endpoint, search, pagination, isLoading, dataFromProps]);

    const handleParamsChange = (field: keyof Pagination, value: any) => {
        get({ ...pagination, [field]: value });
    }

    const handleRowClick = useCallback(async (e: T): Promise<void> => {
        if (actions?.select || actions?.selectMultiple || actions?.delete || actions?.deleteMultiple) {
            if (actions.selectMultiple || actions.deleteMultiple) {
                const _selected = toggleInArray(selected, e, (e1, e2) => primaryKeysEquals(e1, e2, primaryKey));
                if (actions.selectMultiple) actions.selectMultiple(_selected);
                setSelected(_selected);
            } else if (actions.select || actions.delete) {
                const _selected = selected.length && primaryKeysEquals(selected[0], e, primaryKey) ? null : e;
                if (actions.select) actions.select(_selected);
                setSelected(_selected ? [_selected] : []);
            }
        } else if (actions?.click) {
            const result = await actions.click(e);
            if (result === true) {
                get();
            }
        }
    }, [actions, selected, get, primaryKey]);

    const handleRowChange = useCallback(async (e: T): Promise<void> => {
        if (!actions?.change) return;

        const result = await actions.change(e);
        if (result === true) {
            get();
        }
    }, [actions, get]);

    const handleDelete = useCallback(async (e: T[]): Promise<void> => {
        if ((!actions?.delete && !actions?.deleteMultiple) || !e?.length) return;

        const result = actions?.delete
            ? await actions.delete(e[0])
            : await actions.deleteMultiple!(e);

        setSelected([]);
        if (result === true) {
            get();
        }
    }, [actions, get]);

    const rows = useMemo(() => data.map(d => {

        const cells = Object.keys(schema).map(k => {
            let v: ReactNode;
            const c = schema[k as keyof T]!;
            if (c.display && typeof c.display === 'function') {
                v = c.display(d);
            } else if (c.display === 'toggle') {
                v = <Toggle id={k} value={!!d[k as keyof T]} onChange={() => handleRowChange({ ...d, [k]: !d[k as keyof T] })} />;
            } else if (c.display === 'checkbox') {
                v = <Checkbox id={k} value={!!d[k as keyof T]} onChange={() => handleRowChange({ ...d, [k]: !d[k as keyof T] })} />;
            } else {
                v = getStringValue(d[k as keyof T]);
            }

            return (
                <td key={k} className="simple-list-item">{v}</td>
            );
        });

        return (
            <tr
                key={getPrimaryKeysAsString(d, primaryKey)}
                onClick={() => handleRowClick(d)}
                style={(actions?.click || actions?.select || actions?.delete || actions?.selectMultiple) ? { cursor: 'pointer' } : {}}
                className={selected.some(s => primaryKeysEquals(s, d, primaryKey)) ? 'simple-list-row-selected' : ''}
            >
                {cells}
            </tr>
        );
    }
    ), [data, selected, handleRowClick, handleRowChange, actions, schema, primaryKey]);

    useEffect(() => {
        get();
    }, [endpoint, dataFromProps]);

    return (
        <div className="simple-list">
            {(!!withSearch || actions?.create || actions?.add || actions?.delete || actions?.deleteMultiple) && (
                <div className="simple-list-header">
                    {!!withSearch && (
                        <input
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={t('data:search') + '...'}
                        />
                    )}
                    <div className="simple-list-actions">
                        {(!!actions?.delete || !!actions?.deleteMultiple) && !!selected?.length && <Button label={t('actions:delete')} color="error" icon={<CloseIcon />} onClick={() => handleDelete(selected)} />}
                        {!!actions?.create && <Button label={t('actions:create')} color="success" icon={<PlusIcon />} onClick={actions.create} />}
                        {!!actions?.add && <Button label={t('actions:add')} color="success" icon={<PlusIcon />} onClick={actions.add} />}
                    </div>
                </div>
            )}
            <div className="simple-list-table">
                <table>
                    <thead>
                        <tr>
                            {Object.keys(schema).map(k => <th key={k}>{schema[k as keyof T]!.label}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
                {!data?.length && !isLoading && <Nothing inline />}
            </div>
            <PaginationComponent data={data} pagination={pagination} onPageChange={(page) => handleParamsChange('page', page)} />
        </div >
    )
}

export default SimpleList;