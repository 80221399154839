import { Outlet } from "react-router-dom";
import SubNav, { SubNavProps } from "../SubNav";
import './index.scss';

const LayoutSubNav = ({ root, navigation }: SubNavProps) => (
    <div className="layout-sub-nav">
        <SubNav root={root} navigation={navigation} />
        <div className="layout-sub-nav-content">
            <Outlet />
        </div>
    </div>
);

export default LayoutSubNav;