import { ReactNode } from 'react';
import { CloseIcon } from '../../icons';
import './index.scss';
import Button, { ButtonProps } from '../Button';
import { useTranslation } from 'react-i18next';

interface ModalProps {
    className?: string;
    children?: ReactNode;
    header?: string;
    type?: 'small' | 'normal';
    onSubmit?: () => void;
    onClose?: () => void;
    actions?: (ButtonProps & { key: string })[];
}

const Modal = ({ className, header, children, onClose, onSubmit, type = 'normal', actions }: ModalProps) => {
    const { t } = useTranslation();
    return (
        <div className={`modal-backdrop ${children ? 'modal-backdrop-visible' : ''}`}>
            <div className="modal">
                {header && (
                    <div className="modal-header">
                        {header}
                        {onClose && <CloseIcon className="modal-close-icon" onClick={onClose} />}
                    </div>
                )}
                <div className={`modal-content ${className ?? ''}`}>
                    {children}
                </div>
                {(onSubmit || onClose) && (
                    <div className={`modal-footer modal-footer-${type}`}>
                        {actions?.map(a => <Button {...a} />)}
                        {onClose && <Button color="secondary" onClick={onClose} label={t('modal:close')} />}
                        {onSubmit && <Button color="primary" onClick={onSubmit} label={t('modal:submit')} />}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Modal;