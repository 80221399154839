import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useOutsideClick } from '../../hooks';
import './index.scss';
import { DownIcon, UpIcon } from '../../icons';

interface DropdownProps {
    children: ReactNode;
    dropdown: ReactNode;
    disabled?: boolean;
    closeOnClick?: boolean;
    openOnHover?: boolean;
    withIcon?: boolean;
    maxHeight?: string;
    className?: string;
}

const Dropdown = ({ children, dropdown, disabled, maxHeight, className, closeOnClick, withIcon, openOnHover }: DropdownProps) => {
    const [isVisible, setVisible] = useState<boolean>(false);
    const ref = useOutsideClick(useCallback(() => setVisible(false), [isVisible]));

    useEffect(() => {
        if (ref.current) {
            const bound = ref.current.getBoundingClientRect();

            if (bound.top + bound.height > window.innerHeight) {
                ref.current.style.bottom = '0';
                ref.current.style.top = 'auto';
            } else {
                ref.current.style.top = '100%';
                ref.current.style.bottom = 'auto';
            }
            if (bound.left + bound.width > window.innerWidth) {
                ref.current.style.right = '0';
                ref.current.style.left = 'auto';
            } else {
                ref.current.style.left = '0';
                ref.current.style.right = 'auto';
            }
        }
    }, [isVisible, ref]);

    return (
        <div
            className={`dropdown ${className ?? ''} ${isVisible ? 'dropdown-visible' : ''}`}
            onMouseEnter={!!openOnHover && !disabled ? () => setVisible(true) : undefined}
            onMouseLeave={!!openOnHover && !disabled ? () => setVisible(false) : undefined}
        >
            <div
                className={`dropdown-children ${isVisible ? 'focus' : ''}`}
                onClick={!disabled && !openOnHover ? () => setVisible(!isVisible) : undefined}
            >
                {children}
                {isVisible && withIcon && <UpIcon className="dropdown-icon" />}
                {!isVisible && withIcon && <DownIcon className="dropdown-icon" />}
            </div>
            <div
                className="dropdown-content"
                style={maxHeight ? { maxHeight } : undefined}
                ref={ref}
                onClick={() => closeOnClick ? setVisible(false) : null}
            >
                {dropdown}
            </div>
        </div>
    )
}

export default Dropdown;