import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Nav, { NavigationDivider, NavigationItem } from "../Nav";

interface LayoutLeftNavProps {
    navigation: (NavigationItem | NavigationDivider)[];
}

const LayoutLeftNav = ({ navigation }: LayoutLeftNavProps) => (
    <Fragment>
        <Nav navigation={navigation} />
        <div id="content">
            <Outlet />
        </div>
    </Fragment>
);

export default LayoutLeftNav;