import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import { BubbleHead } from '../../ui';
import ImageUploader from '../ImageUploader';
import { useState } from 'react';
import AxiosInstance from '../../services/Axios';
import { useTranslation } from 'react-i18next';
import './index.scss';

interface ImageFieldProps extends FormPropsInterface<string> {
    endpoint: string,
    className?: string,
}

const ImageField = ({
    value,
    endpoint,
    disabled,
    onChange,
    ...rest
}: ImageFieldProps) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const handleChange = async (file: File) => {
        if (isLoading) return;

        setLoading(true);
        try {
            const data = new FormData();
            data.append('image', file);

            const { data: _image } = await AxiosInstance.post<string>(endpoint, data, { headers: { 'Content-Type': 'multipart/form-data' } });

            onChange(_image);
        } catch {

        } finally {
            setLoading(false);
        }

    }

    return (
        <InputWrapper type="image" {...rest}>
            {!!value && <BubbleHead image={value} size="small" />}
            <div className="input-actions">
                {!!value && <span onClick={() => onChange(undefined)}>{t('actions:delete')}</span>}
                <ImageUploader onChange={handleChange}>{t('actions:update')}</ImageUploader>
            </div>
        </InputWrapper>
    );
}

export default ImageField;