import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce, useRequest } from "../../hooks";
import useForm, { EntityValidation, FormHookReturn } from "../../hooks/useForm";
import { CloseIcon, MergeIcon, PlusIcon } from "../../icons";
import { PaginatedResults, Pagination } from "../../models/pagination";
import { Button, Modal } from "../../ui";
import { getStringValue } from "../../utils/format";
import { getPrimaryKeysAsString, hasPrimaryKeys, primaryKeysEquals, toggleInArray } from "../../utils/objects";
import PaginationComponent from "../Pagination";
import SimpleList, { SimpleListSchema } from "../SimpleList";
import ColumnHeader from "./ColumnHeader";
import Row from "./Row";
import "./index.scss";

export type DataListFilters<T> = {
    [U in keyof T]?: DataListColumnFilters<T, U>;
}

export type DataListColumnFilters<T, U extends keyof T> = {
    search?: string;
    startsWith?: string;
    ilike?: string;
    equals?: T[U];
    in?: T[U] extends (Array<infer V> | undefined) ? V[] : T[U][];
    from?: T[U];
    to?: T[U];
    null?: boolean;
}

export type DataListColumn<T, U extends keyof T> = {
    label: string;
    display?: ((e: T) => JSX.Element | string) | 'textinput' | 'textarea' | 'select' | 'select-multiple' | 'date';
    values?: T[U] extends (Array<infer V> | undefined) ? V[] : T[U][];
    options?: {
        edit?: boolean;
        order?: boolean;
        search?: boolean;
        ilike?: boolean;
        startsWith?: boolean;
        equals?: boolean;
        in?: boolean;
        between?: boolean;
        null?: boolean;
    },
    width?: number | string;
    hidden?: boolean;
}

export type DataListColumnWithWidth<T, U extends keyof T> = DataListColumn<T, U> & { widthPx: number };

export type DataListSchemaForState<T> = {
    [U in keyof T]?: DataListColumnWithWidth<T, U>;
};

export type DataListSchema<T> = {
    [U in keyof T]?: DataListColumn<T, U>;
};

export type DataListFormProps<T> = Pick<FormHookReturn<T>, 'entity' | 'onChange' | 'errors' | 'attachInput' | 'onMultipleChange'>;

export interface DataListProps<T> {
    schema: DataListSchema<T>;
    primaryKey: keyof T | (keyof T)[];
    validation?: EntityValidation<T>;
    title: string;
    endpoint: string;
    actions?: {
        view?: boolean | ((e: T) => void);
        select?: boolean | ((e: T[]) => void);
        merge?: boolean | ((e: T[]) => void);
    }
    createForm?: (props: DataListFormProps<T>) => JSX.Element;
    updateForm?: (props: DataListFormProps<T>) => JSX.Element;
}

const SELECT_WIDTH = 50;
const dataListSchemaToSimpleListSchema = <T,>(s: DataListSchema<T>): SimpleListSchema<T> => {
    const schema: SimpleListSchema<T> = {};

    Object.keys(s).forEach((k) => {
        const column = s[k as keyof T]!
        schema[k as keyof T] = {
            label: column.label,
            display: !column.display || typeof column.display === 'function'
                ? column.display
                : ['select', 'select-multiple'].includes(column.display)
                    ? (e: T) => getStringValue(e[k as keyof T])
                    : undefined
        }
    })

    return schema;
}

const DataList = <T,>({
    schema,
    primaryKey,
    validation,
    title,
    endpoint,
    actions,
    createForm: CreateForm,
    updateForm: UpdateForm
}: DataListProps<T>) => {
    const { t } = useTranslation();
    const request = useRequest();
    const tableRef = useRef<HTMLDivElement | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [columns, setColumns] = useState<DataListSchemaForState<T>>({});
    const [data, setData] = useState<T[]>([]);
    const [selectedData, setSelectedData] = useState<T[]>([]);
    const [mergeTarget, setMergeTarget] = useState<T | null>(null);
    const [isMergeModalVisible, setMergeModalVisible] = useState<boolean>(false);
    const [pagination, setPagination] = useState<Pagination | null>(null);
    const [filters, setFilters] = useState<DataListFilters<T>>({});
    const [formMode, setFormMode] = useState<'create' | 'update' | null>(null);
    const { entity: formEntity, validate, hasChanged, reset, ...formProps } = useForm<T>({});
    useDebounce(() => get(), 600, [filters]);

    const simpleListSchema = useMemo(() => dataListSchemaToSimpleListSchema(schema), [schema]);
    const hasFilter = useMemo(() => filters && Object.keys(filters).some(k => !!filters[k as keyof DataListFilters<T>]), [filters]);

    const handleColumnResize = useCallback((key: keyof T, width: number) => {
        const _columns = { ...columns };
        const index = Object.keys(columns).findIndex(k => k === key);
        if (index === -1) return columns;

        if (index < Object.keys(columns).length - 1) {
            const nextKey = Object.keys(columns)[index + 1];
            const diff = (_columns[key]!.widthPx ?? 0) - width;
            _columns[nextKey as keyof T]!.widthPx = (_columns[nextKey as keyof T]!.widthPx ?? 0) + diff;
        }

        _columns[key]!.widthPx = width;

        setColumns(_columns);
    }, [columns]);

    const get = useCallback(async (_pagination?: Partial<Pagination>) => {
        if (isLoading) return;

        setLoading(true);
        request.get<PaginatedResults<T>>(endpoint, {
            params: { ...(_pagination ? _pagination : pagination), ...filters },
            i18n: true,
            loader: true,
            errorMessage: 'error.server_error'
        })
            .then((data) => {
                setData(data.data);
                setPagination(data.pagination);
            })
            .catch(() => null)
            .finally(() => setLoading(false))
    }, [isLoading, pagination, filters, endpoint]);

    const handleFormSubmit = useCallback(async () => {
        if (!hasChanged) {
            reset({});
            setFormMode(null);
            return;
        };

        if (!validation && !validate(validation)) {
            return;
        }
        const isNew = !hasPrimaryKeys(formEntity, primaryKey);

        const requestMethod = isNew ? request.post : request.put;
        const successMessage = isNew ? 'success.create' : 'success.update';

        requestMethod<T>(endpoint, formEntity, {
            i18n: true,
            loader: true,
            successMessage,
            errorMessage: 'error.server_error'
        })
            .then(() => {
                reset({});
                get();
                setFormMode(null);
            })
            .catch(() => null);
    }, [validate, validation, endpoint, get, formEntity, schema, reset, primaryKey, hasChanged]);

    const handleParamsChange = useCallback((field: keyof Pagination, value: any) => {
        get({ ...pagination, [field]: value });
    }, [pagination, get]);

    const handleOrder = useCallback((key: keyof T, direction: 1 | -1) => {
        get({
            ...pagination,
            sortBy: key as string,
            sortOrder: direction,
        });
    }, [pagination, get]);

    const handleFilterChange = useCallback((field: keyof T, columnFilters: DataListColumnFilters<T, keyof T>) => {
        setFilters((filters) => ({ ...filters, [field]: columnFilters }));
    }, []);

    const handleView = useCallback((e: T) => {
        if (typeof actions?.view === 'function') {
            actions.view(e);
        } else if (actions?.view && UpdateForm) {
            reset(e);
            setFormMode('update');
        }
    }, [actions, UpdateForm, reset]);

    const handleSelect = useCallback((e: T) => {
        setSelectedData((selectedData) => {
            const _selectedData = toggleInArray(selectedData, e, (e1, e2) => primaryKeysEquals(e1, e2, primaryKey))
            if (typeof actions?.select === 'function') actions.select(_selectedData);
            return _selectedData;
        })
    }, [actions, schema]);

    const handleMerge = useCallback(() => {
        if (selectedData?.length) {
            if (!isMergeModalVisible) {
                setMergeModalVisible(true);
            } else if (mergeTarget) {
                //
                setMergeModalVisible(false);
                setMergeTarget(null);
                setSelectedData([]);
            }
        }
    }, [actions, selectedData, isMergeModalVisible, mergeTarget]);

    const columnHeaders = useMemo(() => Object.keys(columns).filter(key => !columns[key as keyof T]!.hidden).map((key) => (
        <ColumnHeader
            key={key as string}
            onResize={(s) => handleColumnResize(key as keyof T, s)}
            column={columns[key as keyof T]!}
            order={pagination?.sortBy === key as string ? pagination.sortOrder : undefined}
            onOrder={(direction) => handleOrder(key as keyof T, direction)}
            onFilterChange={(f) => handleFilterChange(key as keyof T, f)}
            filters={filters[key as keyof T]}
        />
    )), [columns, handleColumnResize, handleOrder, handleFilterChange, filters, pagination]);

    const rows = useMemo(() => data.map(d => (
        <Row
            key={getPrimaryKeysAsString(d, primaryKey)}
            entity={d}
            selected={selectedData.some(s => primaryKeysEquals(s, d, primaryKey))}
            columns={columns}
            endpoint={endpoint}
            validation={validation}
            onView={actions?.view ? handleView : undefined}
            onSelect={actions?.select || actions?.merge ? handleSelect : undefined}
        />
    )), [columns, endpoint, schema, actions, handleView, UpdateForm, handleSelect, selectedData, data, primaryKey, validation]);

    useEffect(() => {
        if (tableRef.current) {
            const handleResize = () => {
                if (!tableRef.current) return;

                const actionWidth = actions?.select || actions?.merge ? SELECT_WIDTH : 0;
                const tableWidth = tableRef.current!.getBoundingClientRect().width - actionWidth;

                setColumns((columns) => {
                    const _columns = { ...columns };
                    const oldTableWidth = Object.keys(_columns).reduce((w, key) => w + _columns[key as keyof T]!.widthPx, 0);
                    for (const key in _columns) {
                        _columns[key]!.widthPx = _columns[key]!.widthPx * tableWidth / oldTableWidth
                    }
                    return _columns;
                });
            };
            window.addEventListener('resize', handleResize);

            const _columns: DataListSchemaForState<T> = {};
            const actionWidth = actions?.select || actions?.merge ? SELECT_WIDTH : 0;
            const tableWidth = tableRef.current!.getBoundingClientRect().width - actionWidth;

            for (const key in schema) {
                let w = tableWidth / Object.keys(columns).length;
                const c = schema[key]!;

                if (c.width) {
                    if (typeof c.width === "number") {
                        w = c.width as number;
                    } else {
                        const wString = c.width as string;
                        if (wString.includes('px')) {
                            w = parseInt(wString.replace('px', ''));
                        } else {
                            const percentage = parseInt(wString.replace('%', ''));
                            w = tableWidth * percentage / 100;
                        }
                    }
                }
                _columns[key] = { ...c, widthPx: w };
            }

            const totalWidth = Object.keys(_columns).reduce((w, key) => w + _columns[key as keyof T]!.widthPx, 0);

            if (totalWidth !== tableRef.current!.getBoundingClientRect().width) {
                Object.keys(_columns).forEach((key) => _columns[key as keyof T]!.widthPx = _columns[key as keyof T]!.widthPx * tableWidth / totalWidth);
            }
            setColumns(_columns);

            return () => {
                window.removeEventListener(
                    'onresize',
                    handleResize,
                );
            };
        }
    }, [schema, actions]);

    useEffect(() => {
        setData([]);
        get({});
    }, [endpoint]);

    return (
        <div className="data-list">
            <div className="data-list-header">
                <div className="data-list-header-title">{title}</div>
                <div className="data-list-header-actions">
                    {!!selectedData?.length && (
                        <Button
                            label={t('data:clear_selection')}
                            icon={<CloseIcon />}
                            color="error"
                            onClick={() => setSelectedData([])}
                        />
                    )}
                    {!!actions?.merge && selectedData?.length > 1 && (
                        <Button
                            label={t('data:merge')}
                            color="accent"
                            icon={<MergeIcon />}
                            onClick={handleMerge}
                        />
                    )}
                    {!!hasFilter && (
                        <Button
                            label={t('data:clear_filters')}
                            color="primary"
                            icon={<CloseIcon />}
                            onClick={() => setFilters({})}
                        />
                    )}
                    {!!CreateForm && (
                        <Button
                            label={t('data:new')}
                            icon={<PlusIcon />}
                            color="success"
                            onClick={() => { reset({}); setFormMode('create'); }}
                        />
                    )}
                </div>
            </div>
            <div className="data-list-table" ref={tableRef}>
                <div className="data-list-table-header">
                    {(actions?.select || actions?.merge) && (
                        <div className="data-list-table-header-select" />
                    )}
                    {columnHeaders}
                </div>
                <div className="data-list-table-rows">
                    {rows}
                </div>
            </div>
            <div className="data-list-footer">
                <div className="data-list-pagination-control">
                    <span>Results per page: </span>
                    <select value={pagination?.perPage ?? 20} onChange={(e) => handleParamsChange('perPage', e.target.value)}>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <PaginationComponent data={data} pagination={pagination ?? undefined} onPageChange={(page) => handleParamsChange('page', page)} />
            </div>
            {
                !!formMode && (
                    <Modal
                        type="small"
                        header={
                            hasPrimaryKeys(formEntity, primaryKey)
                                ? t('data:edit')
                                : t('data:new')
                        }
                        onClose={() => setFormMode(null)}
                        onSubmit={handleFormSubmit}
                    >
                        {(formMode === 'update' && UpdateForm) && <UpdateForm entity={formEntity} {...formProps} />}
                        {(formMode === 'create' && CreateForm) && <CreateForm entity={formEntity} {...formProps} />}
                    </Modal>
                )
            }
            {!!isMergeModalVisible && (
                <Modal
                    header={t('data:merge_select_target')}
                    onClose={() => setMergeModalVisible(false)}
                    onSubmit={mergeTarget ? handleMerge : undefined}
                >
                    <SimpleList<T>
                        data={selectedData}
                        primaryKey={primaryKey}
                        schema={simpleListSchema}
                        actions={{ select: (e) => setMergeTarget(e) }}
                    />
                </Modal>
            )}
        </div >
    )
}

export default DataList;