// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.checkbox input {
  opacity: 0;
  height: 0;
  width: 0;
  display: none;
}
.checkbox .mark {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: var(--theme-input-label-color) 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.checkbox .mark:after {
  content: none;
}
.checkbox label {
  margin-left: 8px;
  color: var(--theme-color-main);
  font-size: 12px;
  cursor: pointer;
}
.checkbox label.disabled {
  font-weight: normal;
  color: var(--theme-color-disabled);
}
.checkbox.checked label {
  font-weight: bold;
}
.checkbox.checked .mark {
  border-color: var(--theme-color-success);
}
.checkbox.checked .mark:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 1px;
  background-color: var(--theme-color-success);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/Checkbox/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,yBAAA;EAGA,iBAAA;AACJ;AACI;EACI,UAAA;EACA,SAAA;EACA,QAAA;EACA,aAAA;AACR;AAEI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,gDAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AAAR;AAEQ;EACI,aAAA;AAAZ;AAII;EACI,gBAAA;EACA,8BAAA;EACA,eAAA;EACA,eAAA;AAFR;AAIQ;EACI,mBAAA;EACA,kCAAA;AAFZ;AAOQ;EACI,iBAAA;AALZ;AAOQ;EACI,wCAAA;AALZ;AAOY;EACI,WAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,4CAAA;AALhB","sourcesContent":[".checkbox {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    cursor: pointer;\n    -webkit-user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n\n    & input {\n        opacity: 0;\n        height: 0;\n        width: 0;\n        display: none;\n    }\n\n    & .mark {\n        width: 18px;\n        height: 18px;\n        border-radius: 3px;\n        border: var(--theme-input-label-color) 2px solid;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        flex-shrink: 0;\n\n        &:after {\n            content: none;\n        }\n    }\n\n    & label {\n        margin-left: 8px;\n        color: var(--theme-color-main);\n        font-size: 12px;\n        cursor: pointer;\n\n        &.disabled {\n            font-weight: normal;\n            color: var(--theme-color-disabled);\n        }\n    }\n\n    &.checked {\n        & label {\n            font-weight: bold;\n        }\n        & .mark {\n            border-color: var(--theme-color-success);\n\n            &:after {\n                content: \"\";\n                display: block;\n                width: 10px;\n                height: 10px;\n                border-radius: 1px;\n                background-color: var(--theme-color-success);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
