import { ERR_STRING } from "./constants";

export const normalize = (text: string): string =>
    text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const getStringValue = (obj?: any): string => {
    if (!obj) return "";
    if (obj.hasOwnProperty("toString")) return obj.toString();
    if (Array.isArray(obj)) return obj.map(getStringValue).join(",");

    try {
        return String(obj);
    } catch {
        return ERR_STRING;
    }
};
