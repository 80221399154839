// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover {
  position: relative;
}
.popover > .popover-children {
  cursor: pointer;
  position: relative;
}
.popover > .popover-content {
  width: 100%;
  position: absolute;
  visibility: hidden;
}
.popover.popover-visible > .popover-content {
  visibility: visible;
  width: -moz-fit-content;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Popover/index.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,eAAA;EACA,kBAAA;AACR;AAEI;EACI,WAAA;EACA,kBAAA;EACA,kBAAA;AAAR;AAIQ;EACI,mBAAA;EACA,uBAAA;EAAA,kBAAA;AAFZ","sourcesContent":[".popover {\n    position: relative;\n\n    & > .popover-children {\n        cursor: pointer;\n        position: relative;\n    }\n\n    & > .popover-content {\n        width: 100%;\n        position: absolute;\n        visibility: hidden;\n    }\n\n    &.popover-visible {\n        & > .popover-content {\n            visibility: visible;\n            width: fit-content;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
