export enum ApplicationStatus {
    Available = "AVAILABLE",
    Unavailable = "UNAVAILABLE",
    Disabled = "DISABLED",
}

export enum TwoFactorMethod {
    Email = "EMAIL",
    QrCode = "QRCODE",
}

export enum UserStatus {
    Pending = "PENDING",
    Active = "ACTIVE",
    Suspended = "SUSPENDED",
    Banned = "BANNED",
}
