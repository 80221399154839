// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 8px;
}
.group > .group-label {
  display: flex;
  height: 12px;
}
.group > .group-label > div {
  content: "";
  display: flex;
  flex-shrink: 0;
  width: 16px;
  height: 100%;
  border-top: var(--theme-soft-container-border) 1px solid;
  border-left: var(--theme-soft-container-border) 1px solid;
  border-radius: 4px 0 0 0;
}
.group > .group-label > div:last-child {
  flex-grow: 1;
  border-radius: 0 4px 0 0;
  border-left: none;
  border-right: var(--theme-soft-container-border) 1px solid;
}
.group > .group-label > label {
  position: relative;
  background-color: inherit;
  top: -8px;
  font-size: 12px;
  color: var(--theme-input-label-color);
  padding: 0 8px;
}
.group > .group-content {
  padding: 0 8px 8px 8px;
  border-left: var(--theme-soft-container-border) 1px solid;
  border-right: var(--theme-soft-container-border) 1px solid;
  border-bottom: var(--theme-soft-container-border) 1px solid;
  border-radius: 0 0 4px 4px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Group/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;AACJ;AACI;EACI,aAAA;EACA,YAAA;AACR;AACQ;EACI,WAAA;EACA,aAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,wDAAA;EACA,yDAAA;EACA,wBAAA;AACZ;AACY;EACI,YAAA;EACA,wBAAA;EACA,iBAAA;EACA,0DAAA;AAChB;AAGQ;EACI,kBAAA;EACA,yBAAA;EACA,SAAA;EACA,eAAA;EACA,qCAAA;EACA,cAAA;AADZ;AAKI;EACI,sBAAA;EACA,yDAAA;EACA,0DAAA;EACA,2DAAA;EACA,0BAAA;AAHR","sourcesContent":[".group {\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    margin-top: 8px;\n\n    & > .group-label {\n        display: flex;\n        height: 12px;\n\n        & > div {\n            content: \"\";\n            display: flex;\n            flex-shrink: 0;\n            width: 16px;\n            height: 100%;\n            border-top: var(--theme-soft-container-border) 1px solid;\n            border-left: var(--theme-soft-container-border) 1px solid;\n            border-radius: 4px 0 0 0;\n\n            &:last-child {\n                flex-grow: 1;\n                border-radius: 0 4px 0 0;\n                border-left: none;\n                border-right: var(--theme-soft-container-border) 1px solid;\n            }\n        }\n\n        & > label {\n            position: relative;\n            background-color: inherit;\n            top: -8px;\n            font-size: 12px;\n            color: var(--theme-input-label-color);\n            padding: 0 8px;\n        }\n    }\n\n    & > .group-content {\n        padding: 0 8px 8px 8px;\n        border-left: var(--theme-soft-container-border) 1px solid;\n        border-right: var(--theme-soft-container-border) 1px solid;\n        border-bottom: var(--theme-soft-container-border) 1px solid;\n        border-radius: 0 0 4px 4px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
