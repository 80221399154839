import { Pagination } from "../../models/pagination";
import './index.scss';

interface PaginationProps {
    onPageChange: (page: number) => void;
    pagination?: Pagination;
    data?: any[];
}

const PaginationComponent = ({ pagination, onPageChange, data }: PaginationProps) => !!pagination && !!data && (pagination.page > 1 || data.length >= pagination.perPage)
    ? (
        <div className="pagination">
            {pagination.page > 2 && <span onClick={() => onPageChange(1)} className="arrows">&lt;&lt;</span>}
            {pagination.page > 1 && <span onClick={() => onPageChange(pagination.page - 1)} className="arrows">&lt;</span>}
            {pagination.page - 2 >= 1 && <span onClick={() => onPageChange(pagination.page - 2)} className="dots">...</span>}
            {pagination.page >= 2 && <span onClick={() => onPageChange(pagination.page - 1)} className="page">{pagination.page - 1}</span>}
            {pagination.page && <span className="page active">{pagination.page}</span>}
            {pagination.page + 1 <= pagination.nbPages && <span onClick={() => onPageChange(pagination.page + 1)} className="page">{pagination.page + 1}</span>}
            {pagination.page + 2 <= pagination.nbPages && <span onClick={() => onPageChange(pagination.page + 2)} className="dots">...</span>}
            {pagination.page < pagination.nbPages && <span onClick={() => onPageChange(pagination.page + 1)} className="arrows">&gt;</span>}
            {pagination.page < pagination.nbPages - 1 && <span onClick={() => onPageChange(pagination.nbPages)} className="arrows">&gt;&gt;</span>}
        </div>
    )
    : null;

export default PaginationComponent;