import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useUiContext } from '../../sg-react/context';
import { Password } from '../../sg-react/form';
import { useForm, useRequest } from '../../sg-react/hooks';
import { ValidationRule } from '../../sg-react/hooks/useForm';
import { PasswordVerify } from '../../sg-react/models';
import { Button, Card } from '../../sg-react/ui';
import './index.scss';

const NEW_PASSWORD_VALIDATION = {
    password: [
        { field: 'password', rule: ValidationRule.Required },
        { field: 'password', rule: ValidationRule.Regex, compareTo: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/ }
    ],
    passwordVerify: [{ rule: ValidationRule.EqualsField, compareTo: 'password', error: 'password_match' }],
};

const NewPassword = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { entity, validate, attachInput } = useForm<PasswordVerify>({});
    const { setToastError } = useUiContext();
    const request = useRequest();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [token, setToken] = useState<string>();
    const [type, setType] = useState<string>();
    const navigate = useNavigate();

    const handleSubmit = async (e?: FormEvent): Promise<void> => {
        e?.preventDefault();
        if (isLoading || !validate(NEW_PASSWORD_VALIDATION)) return;

        setLoading(true);
        request.post('/auth/confirm/' + type, { password: entity.password, token }, { successMessage: t('new-password:password-set') })
            .then(() => navigate('/'))
            .catch((e) => {
                if (e?.response?.status === 429) {
                    setToastError(t('message:error.too_many_requests'));
                } else {
                    setToastError(t('message:error.server_error'));
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (searchParams.get('np_tk')) {
            setToken(searchParams.get('np_tk')!);
            setType('new-password');
        } else if (searchParams.get('cs_tk')) {
            setToken(searchParams.get('cs_tk')!);
            setType('confirm-subscription');
        } else {
            navigate('/');
        }
    }, []);

    return (
        <Card title={t('new-password:title')} className="new-password">
            <form onSubmit={handleSubmit}>
                <Password
                    {...attachInput('password')}
                    label={t('users:password')}
                    withProgressBar
                    withWarnings
                    regex={[
                        { re: /.{8,32}/, message: 'password_length' },
                        { re: /[A-Z]/, message: 'password_lowercase' },
                        { re: /[a-z]/, message: 'password_uppercase' },
                        { re: /[0-9]/, message: 'password_digit' },
                        { re: /[#?!@$%^&*-]/, message: 'password_special' },
                    ]}
                />
                <Password
                    {...attachInput('passwordVerify')}
                    label={t('new-password:password-verify')}
                />
                <div id="public-actions">
                    <Button
                        label={t('form:save')}
                        color="accent"
                        type="submit"
                    />
                    <Link to='/'>{t('new-password:back')}</Link>
                </div>
            </form>
        </Card>
    )
}

export default NewPassword;
