import { useCallback, useEffect, useState } from 'react';
import { useForm } from '../../hooks';
import { CalendarTimeIcon } from '../../icons';
import { FixedWrapper } from '../../ui';
import { dateToLocaleString, getMonthsList } from '../../utils/date';
import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import ScrollableList from '../ScrollableList';
import Select from '../Select';
import './index.scss';
import Calendar from './Calendar';

const HOURS = Array.from({ length: 24 }, (_, i) => ({ key: i, label: i.toString().padStart(2, '0') }));
const MINUTES = Array.from({ length: 12 }, (_, i) => ({ key: i * 5, label: (i * 5).toString().padStart(2, '0') }));
const MONTHS = getMonthsList('long').map((m, i) => ({ key: i, label: m }));
const YEARS = Array.from({ length: 40 }, (_, i) => new Date().getFullYear() + 20 - i).map(y => ({ key: y, label: String(y) }));

interface DatePickFormEntity { year: number; month: number; day: number; hours?: number; minutes?: number }

interface DatePickerProps extends FormPropsInterface<Date> {
    min?: Date,
    max?: Date,
    weekDayStart?: number;
    withHour?: boolean;
}

const DatePicker = ({
    value,
    min,
    max,
    weekDayStart,
    withHour,
    onChange,
    disabled,
    ...rest
}: DatePickerProps) => {
    const [displayedDate, setDisplayedDate] = useState<DatePickFormEntity>({
        year: value?.getFullYear() ?? new Date().getFullYear(),
        month: value?.getMonth() ?? new Date().getMonth(),
        day: value?.getDate() ?? new Date().getDate(),
        hours: withHour ? value?.getHours() ?? new Date().getHours() : undefined,
        minutes: withHour ? value?.getMinutes() ?? 0 : undefined
    });
    const [isVisible, setVisible] = useState<boolean>(false);

    const handleSelectDay = useCallback((d: Date) => {
        setDisplayedDate((displayedDate) => ({ ...displayedDate, year: d.getFullYear(), month: d.getMonth(), day: d.getDate() }))
        onChange(d);
        setVisible(false);
    }, [onChange]);

    return (
        <InputWrapper type="date-picker" {...rest}>
            <input
                id={rest.id}
                type="text"
                readOnly
                value={value ? dateToLocaleString(value, withHour) : ''}
                onClick={() => setVisible(true)}
            />
            <CalendarTimeIcon />
            <FixedWrapper visible={isVisible} onClose={() => setVisible(false)}>
                <div className="date-picker-calendar">
                    <ScrollableList id="year" items={YEARS} value={displayedDate.year} onChange={(v) => setDisplayedDate({ ...displayedDate, year: v ?? displayedDate.year })} />
                    <ScrollableList id="month" items={MONTHS} value={displayedDate.month} onChange={(v) => setDisplayedDate({ ...displayedDate, month: v ?? displayedDate.month })} />
                    <Calendar
                        value={new Date(displayedDate.year, displayedDate.month, displayedDate.day)}
                        weekDayStart={1}
                        onDayClick={handleSelectDay}
                    />
                    {withHour && (
                        <div className="date-picker-hour-picker">
                            <Select id="hours" items={HOURS} value={displayedDate.hours} onChange={(hours) => setDisplayedDate({ ...displayedDate, hours: hours as number })} />
                            <Select id="minutes" items={MINUTES} value={displayedDate.minutes} onChange={(minutes) => setDisplayedDate({ ...displayedDate, minutes: minutes as number })} />
                        </div>
                    )}
                </div>
            </FixedWrapper>
        </InputWrapper>
    );
}

export default DatePicker;
