import MapboxGeocoder, { GeocoderOptions } from '@mapbox/mapbox-gl-geocoder';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlPosition, useControl } from 'react-map-gl';

interface GeocoderResult {
    address: string;
    center: [number, number];
    context: {
        id: string,
        language: string,
        language_en: string,
        mapbox_id: string,
        text: string;
        text_en: string;
        wikidata: string;
    }[];
    geometry: { type: string, coordinates: [number, number] }
    place_name: string;
    place_name_en: string;
    place_type: string[];
    properties: { accuracy: string, mapbox_id: string };
    text: string;
    text_en: string;
}

export interface GeocoderResponse {
    streetNumber?: string;
    route?: string;
    city?: string;
    region?: string;
    country?: string;
    postalCode?: string;
    lat: number;
    lng: number;
    address: string;
}

type GeocoderControlrest = Omit<GeocoderOptions, 'accessToken' | 'mapboxgl' | 'marker'> & {
    onSelectResponse?: (response: GeocoderResponse) => void;
    position?: ControlPosition;
};

/* eslint-disable complexity,max-statements */
const Geocoder = ({ onSelectResponse, position, ...rest }: GeocoderControlrest) => {
    const [response, setResponse] = useState<GeocoderResponse | null>(null);
    const { i18n } = useTranslation();

    useEffect(() => {
        if (!!response && onSelectResponse) {
            onSelectResponse(response);
        }
    }, [response])

    useControl<MapboxGeocoder>(() => {
        const ctrl = new MapboxGeocoder({
            ...rest,
            language: i18n.language,
            marker: false,
            accessToken: process.env.REACT_APP_MAPBOX_TOKEN ?? '',
            flyTo: { animate: false }
        });
        ctrl.on('result', (evt) => {
            const { result }: { result: GeocoderResult } = evt;
            const location =
                result &&
                (result.center || (result.geometry?.type === 'Point' && result.geometry.coordinates));

            if (location && onSelectResponse && result?.context) {
                setResponse({
                    streetNumber: result.address,
                    route: result.text,
                    city: result.context.find(c => c.id.startsWith('place'))?.text,
                    region: result.context.find(c => c.id.startsWith('region'))?.text,
                    country: result.context.find(c => c.id.startsWith('country'))?.text,
                    postalCode: result.context.find(c => c.id.startsWith('postcode'))?.text,
                    lat: result.center[1],
                    lng: result.center[0],
                    address: result.place_name
                });
            }
        });
        return ctrl;
    },
        { position: position ?? 'top-left' }
    );

    return null;
}

export default Geocoder;