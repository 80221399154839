import { ReactNode } from 'react';
import './index.scss';

interface CardProps {
    children: ReactNode;
    title?: string;
    className?: string;
    id?: string;
}

const Card = ({ children, title, className, id }: CardProps) => {
    return (
        <div className={`card ${className ?? ''}`} id={id}>
            {!!title && <div className="card-title">{title}</div>}
            {children}
        </div>
    )
}

export default Card;