import { useTranslation } from 'react-i18next';
import { FileSearchIcon } from '../../icons';
import './index.scss';
import { ReactNode } from 'react';

interface NothingProps {
    children?: ReactNode;
    inline?: boolean;
}

const Nothing = ({ children, inline }: NothingProps) => {
    const { t } = useTranslation();

    return (
        <div className={`nothing ${inline ? 'nothing-inline' : ''}`}>
            <FileSearchIcon />
            {!children && <span>{t('ui:nothing')}</span>}
            {children}
        </div>
    )
}

export default Nothing;