import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import logo from '../../assets/images/logos/logo_white.png';
import { useAuthContext, useUiContext } from "../../context";
import { LogoutIcon } from "../../icons";
import defaultAvatar from '../../icons/assets/user-tie.svg';
import BubbleHead from "../BubbleHead";
import Tooltip from "../Tooltip";
import './index.scss';
import { Role } from "../../utils/enums";
import { isType } from "../../utils/objects";

export interface NavigationItem {
    key: string;
    path: string;
    icon?: ReactNode;
    roles?: Role[];
}

export interface NavigationDivider {
    key: string;
    roles?: Role[];
}

interface NavProps {
    navigation: (NavigationItem | NavigationDivider)[];
}

const Nav = ({ navigation }: NavProps) => {
    const { currentUser, currentRole, logout } = useAuthContext();
    const { toggleNav, isNavToggled } = useUiContext();
    const { t } = useTranslation();
    const location = useLocation();

    const navigationItems = useMemo(() => navigation
        .filter(item => !item.roles || (currentRole && item.roles.includes(currentRole)))
        .map(item => {
            if (isType<NavigationItem>(item, (o) => o.hasOwnProperty('path'))) {
                return (
                    <Link
                        key={item.key}
                        to={'/' + item.path}
                        className={`nav-item ${(!item.path && location.pathname === '/') || ('/' + item.path === location.pathname || location.pathname.startsWith('/' + item.path + '/')) ? 'active' : ''}`}
                    >
                        {!!item.icon && <div><Tooltip tooltip={!isNavToggled ? t('path:' + item.key) : ''}>{item.icon}</Tooltip></div>}
                        <div>{t('path:' + item.key)}</div>
                    </Link>
                );
            }
            return <div key={item.key} className="nav-divider" />
        }), [navigation, currentRole, currentUser, isNavToggled, location.pathname])

    return (
        <div id="nav" className={isNavToggled ? 'toggled' : ''}>
            <div id="supply-graph" className="nav-item">
                <div onClick={toggleNav}><img src={logo} alt="Supply-Graph" /></div>
                <div className="nav-label">
                    <span>Supply-Graph</span>
                    <span>Industrie 4.0</span>
                </div>
            </div>
            {navigationItems}
            <div id="nav-user">
                <a className="nav-item" href={process.env.REACT_APP_AUTH_PORTAL} target="_blank" rel="noreferrer">
                    <div>
                        <Tooltip tooltip={!isNavToggled ? currentUser.fullName : ''}>
                            <BubbleHead image={currentUser.avatar ?? defaultAvatar} alt="Avatar" size="small" />
                        </Tooltip>
                    </div>
                    <div>
                        {!!currentUser.fullName && <span>{currentUser.fullName}</span>}
                        <span>{currentUser.email ? currentUser.email : ''}</span>
                    </div>
                </a>
                <div onClick={logout} className="nav-item">
                    <div><Tooltip tooltip={!isNavToggled ? t('auth:logout') : ''}><LogoutIcon /></Tooltip></div>
                    <div>
                        {t('auth:logout')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nav;