// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=select-editable] {
  position: relative;
}
.input[input-type=select-editable] > .input-body > .input-field > .dropdown {
  width: 100%;
}
.input[input-type=select-editable] > .input-body > .input-field > .dropdown .dropdown-children {
  display: flex;
}
.input[input-type=select-editable] > .input-body > .input-field > .dropdown .select-editable-items {
  display: flex;
  flex-direction: column;
}
.input[input-type=select-editable] > .input-body > .input-field > .dropdown .select-editable-items > .select-editable-item {
  display: flex;
}
.input[input-type=select-editable] > .input-body > .input-field > .dropdown .select-editable-items > .select-editable-item > .select-editable-item-icon > svg {
  max-width: 24px;
  max-height: 24px;
}
.input[input-type=select-editable] > .input-body > .input-field > .dropdown .select-editable-add {
  display: flex;
}
.input[input-type=select-editable] > .input-body > .input-field > .dropdown .select-editable-add > svg {
  max-width: 24px;
  max-height: 24px;
}
.input[input-type=select-editable] .dropdown-content {
  width: 100%;
}
.input[input-type=select-editable] .dropdown-content > * {
  width: 100%;
}
.input[input-type=select-editable] .dropdown-content > .select-editable-search {
  display: flex;
  align-items: center;
  justify-content: center;
}
.input[input-type=select-editable] .dropdown-content > .select-editable-search > svg {
  max-width: 24px;
  max-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/SelectEditable/index.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,WAAA;AACR;AACQ;EACI,aAAA;AACZ;AAEQ;EACI,aAAA;EACA,sBAAA;AAAZ;AAEY;EACI,aAAA;AAAhB;AAEgB;EACI,eAAA;EACA,gBAAA;AAApB;AAKQ;EACI,aAAA;AAHZ;AAKY;EACI,eAAA;EACA,gBAAA;AAHhB;AAQI;EACI,WAAA;AANR;AAQQ;EACI,WAAA;AANZ;AASQ;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AAPZ;AASY;EACI,eAAA;EACA,gBAAA;AAPhB","sourcesContent":[".input[input-type=\"select-editable\"] {\n    position: relative;\n\n    & > .input-body > .input-field > .dropdown {\n        width: 100%;\n\n        & .dropdown-children {\n            display: flex;\n        }\n\n        & .select-editable-items {\n            display: flex;\n            flex-direction: column;\n\n            & > .select-editable-item {\n                display: flex;\n\n                & > .select-editable-item-icon > svg {\n                    max-width: 24px;\n                    max-height: 24px;\n                }\n            }\n        }\n\n        & .select-editable-add {\n            display: flex;\n\n            & > svg {\n                max-width: 24px;\n                max-height: 24px;\n            }\n        }\n    }\n\n    & .dropdown-content {\n        width: 100%;\n\n        & > * {\n            width: 100%;\n        }\n\n        & > .select-editable-search {\n            display: flex;\n            align-items: center;\n            justify-content: center;\n\n            & > svg {\n                max-width: 24px;\n                max-height: 24px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
