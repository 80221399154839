import { useState } from 'react';
import React from 'react';
import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import './index.scss';
import { HideIcon, ShowIcon } from '../../icons';

interface PasswordProps extends FormPropsInterface<string> {
    placeholder?: string,
    regex?: { re: RegExp, message: string }[],
    onBlur?: (field: string, value: string) => void,
    onFocus?: (field: string, value: string) => void,
    withProgressBar?: boolean,
    withWarnings?: boolean,
    withShowIcon?: boolean,
}

const Password = ({
    id,
    value,
    placeholder,
    regex,
    onChange,
    onBlur,
    onFocus,
    withProgressBar = false,
    withWarnings = false,
    withShowIcon = false,
    errors,
    warnings,
    ...rest
}: PasswordProps) => {
    const [regexWarning, setRegexWarning] = useState<string>('');
    const [progressBarSize, setProgressBarSize] = useState<number>(0);
    const [isProgressBarVisible, setProgressBarVisible] = useState<boolean>(false);
    const [isPasswordShown, setPasswordShown] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const _value = e.target.value;
        if (!_value || !withWarnings || !regex?.length) {
            setProgressBarSize(100);
        } else {
            let regexWarnings = [];

            for (const re of regex) {
                if (!_value.match(re.re)) {
                    regexWarnings.push(re.message);
                }
            }

            setProgressBarSize((regexWarnings.length) * 100 / regex.length);
            setRegexWarning(regexWarnings.length ? regexWarnings[0] : '');
        }

        if (typeof onChange === 'function') {
            onChange(_value);
        }
    }

    return (
        <InputWrapper
            id={id}
            type="password"
            icon={withShowIcon ? (isPasswordShown
                ? <HideIcon onClick={() => setPasswordShown(!isPasswordShown)} />
                : <ShowIcon onClick={() => setPasswordShown(!isPasswordShown)} />) : null
            }
            errors={!withProgressBar || !isProgressBarVisible ? errors : []}
            warnings={!withProgressBar || !isProgressBarVisible ? [...(warnings ?? []), ...(regexWarning ? [regexWarning] : [])] : []}
            {...rest}
        >
            <input
                autoComplete="off"
                id={id}
                type={isPasswordShown ? "text" : "password"}
                onChange={handleChange}
                onFocus={() => setProgressBarVisible(true)}
                onBlur={() => setProgressBarVisible(false)}
                value={value ?? ''}
                placeholder={placeholder ?? ''}
            />
            {withProgressBar && isProgressBarVisible && (
                <div className="password-progress">
                    <div className="password-progress-cursor" style={{ width: progressBarSize + "%" }} />
                </div>
            )}
        </InputWrapper>
    );
}

export default Password;