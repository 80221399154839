import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TwoFactorMethod } from '../../models/enums';
import { User, UserSettings } from '../../models/user';
import { useAuthContext, useUiContext } from '../../sg-react/context';
import { Select, Switch } from '../../sg-react/form';
import { useForm, useRequest } from '../../sg-react/hooks';
import { FloppyIcon } from '../../sg-react/icons';
import AxiosInstance from '../../sg-react/services/Axios';
import { Button, Modal } from '../../sg-react/ui';
import { ToastType } from '../../sg-react/utils/enums';
import './index.scss';

const VALIDATION = {};

interface QrCodeModalProps {
    onClose: () => void;
}

const QrCodeModal = ({ onClose }: QrCodeModalProps) => {
    const [qrCode, setQrCode] = useState<string | null>(null);
    const request = useRequest();

    useEffect(() => {
        request.get<string>('/auth/qr-code', { i18n: true, errorMessage: 'error.server_error' })
            .then(setQrCode)
            .catch(() => null);
    }, []);

    if (!qrCode) return null;

    return (
        <Modal onClose={onClose}>
            <img src={qrCode} alt="" />
        </Modal>
    );
}

const Settings = () => {
    const { currentUser, refreshUser } = useAuthContext();
    const { entity, validate, attachInput, setEntity } = useForm<UserSettings>({});
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isModalQrCodeVisible, setModalQrCodeVisible] = useState<boolean>(false);
    const { t } = useTranslation();
    const request = useRequest();

    const handleSubmit = async (e?: FormEvent): Promise<void> => {
        e?.preventDefault();
        if (isLoading || !validate(VALIDATION)) return;

        setLoading(true)
        request.patch<User>('/users/settings', entity, { i18n: true, loader: true, successMessage: 'success.update', errorMessage: 'error.server_error' })
            .then(() => refreshUser())
            .catch(() => null)
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setEntity(currentUser);
    }, []);

    return (
        <div id="settings">
            <div id="title">{t('path:settings')}</div>
            <form onSubmit={handleSubmit}>
                <Switch items={Object.values(TwoFactorMethod)} i18n="users" label {...attachInput('twoFactorMethod')} />
                {entity.twoFactorMethod === TwoFactorMethod.QrCode && (
                    <div
                        onClick={() => setModalQrCodeVisible(true)}
                        id="settings-display-qrcode"
                        className="link"
                    >
                        {t('users:show_qrcode')}
                    </div>
                )}
                <Select i18n="users" label items={[{ key: 'en', label: 'English' }, { key: 'fr', label: 'Français' }]} {...attachInput('language')} />
                <div id="actions">
                    <Button
                        label={t('form:save')}
                        icon={<FloppyIcon />}
                        type="submit"
                        color="accent"
                        loading={isLoading}
                    />

                </div>
            </form>
            {isModalQrCodeVisible && (
                <QrCodeModal onClose={() => setModalQrCodeVisible(false)} />
            )}
        </div>
    )
}

export default Settings;