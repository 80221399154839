import { ChangeEvent, FormEvent, KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthContext, useUiContext } from '../../sg-react/context';
import { Password, TextField } from '../../sg-react/form';
import { useForm, useRequest } from '../../sg-react/hooks';
import { ValidationRule } from '../../sg-react/hooks/useForm';
import { LoginCredentials, TokenParsed } from '../../sg-react/models';
import AxiosInstance from '../../sg-react/services/Axios';
import { Button, Card } from '../../sg-react/ui';
import { ToastType } from '../../sg-react/utils/enums';
import './index.scss';

const LOGIN_VALIDATION = {
    email: [
        { field: 'email', rule: ValidationRule.Required },
        { field: 'email', rule: ValidationRule.Email },
    ],
    password: [{ field: 'password', rule: ValidationRule.Required }],
};

const DEFAULT_CODE = ['', '', '', '', '', ''];


const Login = () => {
    const { t } = useTranslation();
    const { entity, validate, attachInput } = useForm<LoginCredentials>({ email: "", password: "" });
    const [searchParams] = useSearchParams();
    const { setToastError } = useUiContext();
    const request = useRequest();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [twoFactorToken, setTwoFactorToken] = useState<string | null>(null);
    const [twoFactorCode, setTwoFactorCode] = useState<string[]>([...DEFAULT_CODE]);
    const { setRefreshToken } = useAuthContext();
    const navigate = useNavigate();

    const handleSubmit = async (e?: FormEvent): Promise<void> => {
        e?.preventDefault();
        if (isLoading || !validate(LOGIN_VALIDATION)) return;

        setLoading(true);
        request.post<{ token: string }>('/auth/login', entity)
            .then((data) => {
                setTwoFactorToken(data.token);
                setTwoFactorCode([...DEFAULT_CODE]);
            })
            .catch((e) => {
                if ((e as any)?.response?.status === 429) {
                    setToastError(t('message:error.too_many_requests'));
                } else if ((e as any)?.response?.status === 401) {
                    setToastError(t('message:error.bad_credentials'));
                } else {
                    setToastError(t('message:error.server_error'));
                }
            })
            .finally(() => setLoading(false))
    }

    const handleTwoFactorSubmit = async (e?: FormEvent) => {
        e?.preventDefault();
        if (isLoading || !twoFactorToken) return;

        setLoading(true);
        request.post<{ token: string, tokenParsed: TokenParsed }>('/auth/login-two-factor', { token: twoFactorToken, code: twoFactorCode.join('') })
            .then((data) => {
                setRefreshToken(data.token);
                if (searchParams.get('redirect_uri')) {
                    window.location.replace(searchParams.get('redirect_uri')!);
                } else {
                    navigate(0);
                }
            })
            .catch((e) => {
                if ((e as any)?.response?.status === 429) {
                    setToastError(t('message:error.too_many_requests'));
                } else if ((e as any)?.response?.status === 401) {
                    setToastError(t('message:error.bad_credentials'));
                } else {
                    setToastError(t('message:error.server_error'));
                }
            })
            .finally(() => setLoading(false))
    }

    const handleCodeChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const index = parseInt(e.target.id);
        if (isNaN(index)) return;

        const _code = [...twoFactorCode];

        _code[index] = _code[index].length === 0 || e.target.value.length === 0 ? e.target.value : _code[index];
        setTwoFactorCode(_code);

        if (_code[index].length === 1 && index < 5) {
            (e.target.form?.[index + 1] as HTMLInputElement).focus();
        }
    }

    const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (e.code === 'Backspace') {
            const element = e.target as HTMLInputElement;
            const index = parseInt(element.id);
            if (isNaN(index)) return;

            if (index > 0) {
                (element.form?.[index - 1] as HTMLInputElement).focus();
            }
        }
    }

    return (
        <Card title={t('login:title')} className="login">
            {!twoFactorToken
                ? (
                    <form onSubmit={handleSubmit}>
                        <TextField
                            {...attachInput('email')}
                            label={t('users:email')}
                            autoComplete
                        />
                        <Password
                            {...attachInput('password')}
                            label={t('users:password')}
                        />
                        <div id="public-actions">
                            <Button
                                label={t('login:login')}
                                color="accent"
                                type="submit"
                                loading={isLoading}
                            />
                            <Link to='/recovery'>{t('login:recovery')}</Link>
                        </div>
                    </form>
                ) : (
                    <form onSubmit={handleTwoFactorSubmit} id="two-factor-form">
                        <label>{t('login:code')}</label>
                        <div id="two-factor-form-helper">{t('login:two_factor')}</div>
                        <div id="two-factor-input">
                            <input
                                id="0"
                                autoComplete="off"
                                value={twoFactorCode[0]}
                                onChange={handleCodeChange}
                                onKeyUp={handleKeyUp}
                            />
                            <input
                                id="1"
                                autoComplete="off"
                                value={twoFactorCode[1]}
                                onChange={handleCodeChange}
                                onKeyUp={handleKeyUp}
                            />
                            <input
                                id="2"
                                autoComplete="off"
                                value={twoFactorCode[2]}
                                onChange={handleCodeChange}
                                onKeyUp={handleKeyUp}
                            />
                            <input
                                id="3"
                                autoComplete="off"
                                value={twoFactorCode[3]}
                                onChange={handleCodeChange}
                                onKeyUp={handleKeyUp}
                            />
                            <input
                                id="4"
                                autoComplete="off"
                                value={twoFactorCode[4]}
                                onChange={handleCodeChange}
                                onKeyUp={handleKeyUp}
                            />
                            <input
                                id="5"
                                autoComplete="off"
                                value={twoFactorCode[5]}
                                onChange={handleCodeChange}
                                onKeyUp={handleKeyUp}
                            />
                        </div>
                        <div id="public-actions">
                            <Button
                                label={t('login:login')}
                                color="accent"
                                type="submit"
                                loading={isLoading}
                            />
                            <Link to='/recovery'>{t('login:recovery')}</Link>
                        </div>
                    </form>
                )}
        </Card>
    )
}

export default Login;
