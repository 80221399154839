import { CSSProperties, ReactNode, useEffect, useRef } from "react";
import './index.scss';
import { useOutsideClick } from "../../hooks";

interface BackdropWrapperProps {
    children: ReactNode;
    visible?: boolean;
    onClose: () => void;
}

const BackdropWrapper = ({ children, visible, onClose }: BackdropWrapperProps) => {
    const ref = useOutsideClick(() => onClose());
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current && ref.current) {
            if (visible) {
                const bbox = containerRef.current.getBoundingClientRect();

                ref.current.style.position = 'fixed';
                ref.current.style.top = bbox.top + 'px';
                ref.current.style.left = bbox.left + 'px';
                ref.current.style.width = bbox.width + 'px';
            } else {
                ref.current.style.position = 'relative';
                ref.current.style.top = 'unset';
                ref.current.style.left = 'unset';
            }
        }
    }, [visible]);

    return (
        <div className="backdrop-wrapper" ref={containerRef}>
            <div className="backdrop" style={visible ? { display: 'block' } : { display: 'none' }} />
            <div className={`backdrop-children ${visible ? 'backdrop-children-visible' : ''}`} ref={ref}>
                {children}
            </div>
        </div>
    )
}

export default BackdropWrapper;