import React, { Fragment, useEffect, useState } from 'react';
import { CloseIcon, MagnifierIcon, PlusIcon, SuccessIcon } from '../../icons';
import { Dropdown } from '../../ui';
import { normalize } from '../../utils/format';
import ColorPicker from '../ColorPicker';
import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import './index.scss';

export type ListItem = { _id: string, field: string, value: string, color?: string };

interface SelectEditableValueProps {
    arrayValue: string[],
    listItems: ListItem[],
}

const SelectEditableValue = ({
    arrayValue,
    listItems,
}: SelectEditableValueProps) => {
    const [selectedItems, setSelectedItems] = useState<ListItem[]>([])

    useEffect(() => {
        setSelectedItems(listItems.filter(item => arrayValue.includes(item._id)));
    }, [listItems, arrayValue]);

    return (
        <div className="select-editable-items">
            {selectedItems.map((item) => (
                <div key={item._id} className={`select-editable-item-value ${item.color ? 'background-palette' + item.color : ''}`}>
                    {item.value}
                </div>
            ))}
        </div>
    )
}

export interface SelectEditableProps extends FormPropsInterface<string[] | string> {
    multiple?: boolean,
    colors?: string[],
    items?: ListItem[],
    maxHeight?: string;
    getItems?: () => Promise<ListItem[] | void>,
    postItem?: (item: Omit<ListItem, '_id' | 'field'>) => Promise<ListItem | void>,
}

const SelectEditable = ({
    value,
    items,
    getItems,
    postItem,
    disabled,
    maxHeight = '350px',
    multiple,
    colors,
    onChange,
    ...rest
}: SelectEditableProps) => {
    const [listItems, setListItems] = useState<ListItem[]>([]);
    const [filteredItems, setFilteredItems] = useState<ListItem[]>([]);
    const [arrayValue, setArrayValue] = useState<string[]>([]);
    const [itemToAdd, setItemToAdd] = useState<Omit<ListItem, '_id' | 'field'>>({ value: '' });
    const [itemSearch, setItemSearch] = useState<string>();
    const [isLoading, setLoading] = useState<boolean>(false);

    const handlePostItem = async () => {
        if (!postItem || isLoading) return;

        setLoading(true);
        const _item = await postItem(itemToAdd);

        if (_item) {
            setListItems([...listItems, _item]);
        }
        setLoading(false);
    }

    const handleGetItems = async () => {
        if (!getItems || isLoading) return;

        setLoading(true);
        const _listItems = await getItems();

        if (_listItems) setListItems(_listItems);
        setLoading(false);
    }

    useEffect(() => {
        setFilteredItems(itemSearch
            ? listItems.filter(item => normalize(item.value).toLowerCase().startsWith(normalize(itemSearch).toLowerCase()))
            : listItems
        );
    }, [listItems, itemSearch]);

    useEffect(() => {
        if (getItems) {
            handleGetItems();
        } else if (items?.length) {
            setListItems(items);
        }
    }, [getItems, items]);

    useEffect(() => {
        if (!value?.length) {
            setArrayValue([]);
        } else if (Array.isArray(value)) {
            setArrayValue(value);
        } else {
            setArrayValue([value]);
        }
    }, [value]);

    const toggleItem = (_id: string) => {
        let _value = [...arrayValue];
        const index = _value.indexOf(_id);

        if (index === -1) {
            if (!multiple) {
                _value = [_id];
            } else {
                _value.push(_id);
            }
        } else {
            _value.splice(index, 1);
        }

        onChange(multiple ? _value : _value[0]);
    }

    const submitOnEnter = (e: React.KeyboardEvent<HTMLElement>) => {
        if ((e.code === "Enter" || e.code === "NumpadEnter") && !e.shiftKey && postItem) {
            e.preventDefault();
            handlePostItem();
        }
    }

    return (
        <InputWrapper type="select-editable" {...rest}>
            <Dropdown
                maxHeight={maxHeight}
                closeOnClick={!multiple}
                dropdown={
                    <Fragment>
                        <div className="select-editable-search" onClick={(e) => e.stopPropagation()}>
                            <input type="text" placeholder="Filtrer les valeurs..." value={itemSearch} onChange={(e) => setItemSearch(e.target.value)} />
                            <CloseIcon onClick={() => setItemSearch('')} />
                            <MagnifierIcon />
                        </div>
                        <div className="select-editable-items">
                            {filteredItems.map((item) => (
                                <div onClick={() => toggleItem(item._id)}
                                    key={item._id}
                                    className="select-editable-item"
                                >
                                    <div className="select-editable-item-icon">
                                        {!!arrayValue?.includes(item._id) && <SuccessIcon />}
                                    </div>
                                    <div className={`select-editable-item-value ${item.color ? 'background-palette' + item.color : ''}`}>
                                        {item.value}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {postItem && (
                            <div className="select-editable-add" onClick={(e) => e.stopPropagation()}>
                                {!!colors?.length && (
                                    <ColorPicker
                                        id="item-color"
                                        colors={colors}
                                        value={itemToAdd.color}
                                        onChange={(color) => setItemToAdd({ ...itemToAdd, color })}
                                    />
                                )}
                                <input
                                    type="text"
                                    value={itemToAdd?.value}
                                    onChange={(e) => setItemToAdd({ ...itemToAdd, value: e.target.value })}
                                    onKeyDown={submitOnEnter}
                                />
                                <PlusIcon onClick={handlePostItem} />
                            </div>
                        )}
                    </Fragment>}
            >
                <SelectEditableValue  {...{ arrayValue, listItems }} />
            </Dropdown>
        </InputWrapper>
    )
}

export default SelectEditable;
