import { Fragment, useCallback, useMemo, useState } from "react";
import { Marker } from "react-map-gl";
import { Map } from "../../data";
import { GeocoderResponse } from "../../data/Map/Geocoder";
import { LocationIcon } from "../../icons";
import TextField, { TextFieldProps } from "../TextField";
import './index.scss';
import { Modal } from "../../ui";

interface AddressPickerProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
    entity?: Partial<GeocoderResponse>;
    onMultipleChange: (e: GeocoderResponse) => void;
}

const AddressPicker = ({ id, entity, onMultipleChange, small, label, i18n, inline, errors, warnings }: AddressPickerProps) => {
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [geocoderResponse, setGeocoderResponse] = useState<GeocoderResponse | null>(null);

    const marker = useMemo(() => (geocoderResponse?.lng && geocoderResponse.lat) || (entity?.lng && entity.lat)
        ? [
            <Marker
                key={1}
                longitude={geocoderResponse?.lng ?? entity!.lng!}
                latitude={geocoderResponse?.lat ?? entity!.lat!}
                color="#3288bd"
            />
        ]
        : []
        , [geocoderResponse, entity])

    const handleClose = useCallback(() => {
        setModalVisible(false);
        setGeocoderResponse(null);
    }, []);


    const handleSubmit = useCallback(() => {
        if (geocoderResponse) {
            onMultipleChange(geocoderResponse);
        }
        handleClose();
    }, [onMultipleChange, geocoderResponse, handleClose]);

    return (
        <Fragment>
            <TextField
                icon={<LocationIcon />}
                id={id}
                value={entity?.address}
                onChange={() => null}
                disabled
                onFocus={() => setModalVisible(true)}
                {...{ small, inline, i18n, label, errors, warnings }}
            />
            {!!isModalVisible && (
                <Modal onClose={handleClose} onSubmit={handleSubmit} className="address-picker-modal">
                    <Map
                        markers={marker}
                        withGeocoder
                        onGeocoderSelectResponse={setGeocoderResponse}
                        fitToMarkers
                    />
                </Modal>
            )}
        </Fragment>
    )
}

export default AddressPicker;