export const getMonthsList = (
    format?: "numeric" | "2-digit" | "long" | "short" | "narrow"
): string[] =>
    Array.from({ length: 12 }, () => new Date()).map((date, i) => {
        date.setMonth(i);
        return date.toLocaleDateString("fr-FR", { month: format });
    });

export const getWeekdaysList = (
    weekDayStart: number,
    format?: "long" | "short" | "narrow"
): string[] =>
    Array.from({ length: 7 }, () => new Date()).map((date, i) => {
        date.setDate(
            date.getDate() + ((weekDayStart ?? 0) + i - date.getDay())
        );
        return date.toLocaleDateString("fr-FR", { weekday: format });
    });

export const areSameDay = (date1: Date, date2: Date): boolean =>
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

const isoDateFormat = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

export const isIsoDateString = (value: any): boolean =>
    value && typeof value === "string" && isoDateFormat.test(value);

export const isEventOnDay = (
    eventStart: Date,
    eventEnd: Date,
    dayStart: Date,
    dayEnd: Date
): boolean =>
    (eventStart >= dayStart && eventStart <= dayEnd) ||
    (eventEnd >= dayStart && eventEnd <= dayEnd) ||
    (eventStart <= dayStart && eventEnd >= dayEnd);

export const isEventOnFullDay = (
    eventStart: Date,
    eventEnd: Date,
    day: Date
): boolean =>
    areSameDay(eventStart, day) ||
    areSameDay(eventEnd, day) ||
    (eventStart <= day && eventEnd >= day);

export const getHourDiff = (e1: Date, e2: Date): number =>
    Math.abs(e2.getTime() - e1.getTime()) / (1000 * 60 * 60);

export const dateToLocaleString = (date: Date, withTime?: boolean): string =>
    date.toLocaleDateString(
        "fr-FR",
        withTime
            ? {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
              }
            : { year: "2-digit", month: "2-digit", day: "2-digit" }
    );
