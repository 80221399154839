// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/sauron/screenshot.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#access {
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin: auto;
  max-width: 800px;
}
#access > #title {
  font-size: 24px;
  color: var(--theme-container-title-color);
  margin-bottom: 18px;
}
#access > .card {
  padding: 0;
  width: 800px;
  background-image: linear-gradient(to left, rgba(183, 215, 254, 0.56), rgba(91, 164, 212, 0.75)), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 100%;
  height: 400px;
  background-size: cover;
  align-items: flex-end;
  justify-content: flex-end;
  color: white;
  padding: 36px 48px;
}
#access > .card > a {
  font-size: 14px;
  color: var(--theme-color-white);
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}
#access > .card > a > svg {
  width: 16px;
  height: 16px;
  fill: var(--theme-color-white);
}`, "",{"version":3,"sources":["webpack://./src/pages/access/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;AACJ;AACI;EACI,eAAA;EACA,yCAAA;EACA,mBAAA;AACR;AAEI;EACI,UAAA;EACA,YAAA;EACA,wIAAA;EAMA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;AALR;AAOQ;EACI,eAAA;EACA,+BAAA;EACA,iBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AALZ;AAOY;EACI,WAAA;EACA,YAAA;EACA,8BAAA;AALhB","sourcesContent":["#access {\n    display: flex;\n    flex-direction: column;\n    padding: 24px;\n    margin: auto;\n    max-width: 800px;\n\n    & > #title {\n        font-size: 24px;\n        color: var(--theme-container-title-color);\n        margin-bottom: 18px;\n    }\n\n    & > .card {\n        padding: 0;\n        width: 800px;\n        background-image: linear-gradient(\n                to left,\n                rgba(183, 215, 254, 0.56),\n                rgba(91, 164, 212, 0.75)\n            ),\n            url(\"../../assets/sauron/screenshot.png\");\n        width: 100%;\n        height: 400px;\n        background-size: cover;\n        align-items: flex-end;\n        justify-content: flex-end;\n        color: white;\n        padding: 36px 48px;\n\n        & > a {\n            font-size: 14px;\n            color: var(--theme-color-white);\n            font-weight: bold;\n            cursor: pointer;\n            display: flex;\n            align-items: center;\n            gap: 8px;\n\n            & > svg {\n                width: 16px;\n                height: 16px;\n                fill: var(--theme-color-white);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
