import { FormPropsInterface } from '../InputWrapper';
import './index.scss';

const Checkbox = ({
    id,
    value,
    label,
    className,
    disabled,
    onChange,
}: FormPropsInterface<boolean>) => (
    <div
        className={`checkbox ${value ? 'checked' : ''} ${className ?? ''}`}
        onClick={typeof onChange === 'function' && !disabled ? (e) => { e.stopPropagation(); onChange(!value); } : undefined}
    >
        <input
            type="checkbox"
            checked={!!value}
            onChange={typeof onChange === 'function' && !disabled ? (e) => { e.stopPropagation(); onChange(!value); } : undefined}
            id={id}
            name={id}
        />
        <div className="mark" />
        {!!label && <label className={disabled ? 'disabled' : ''}>{label}</label>}
    </div>
);

export default Checkbox;