import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '..';
import { CheckIcon, CircleHelpIcon } from '../../icons';
import './index.scss';

export interface StepComponentProps<T> {
    onChange: (e: Partial<T>) => void;
    value: Partial<T>;
    errors?: Record<string, string[]>;
}

export interface ValidationComponentProps<T> {
    value: Partial<T>;
}

export interface Step<T> {
    key: string;
    label?: string;
    description?: string;
    icon: ReactNode;
    validation: (e: Partial<T>) => boolean;
    component: (p: StepComponentProps<T>) => JSX.Element;
    validationComponent: (p: ValidationComponentProps<T>) => JSX.Element;
    onSubmit?: (e: Partial<T>) => [boolean, Record<string, string[]>];
}

interface StepperProps<T> {
    steps: Step<T>[];
    i18n?: string;
    initialValue?: Partial<T>;
    onSubmit: (e: Partial<T>) => void;
}

const Stepper = <T,>({ steps, i18n, initialValue, onSubmit }: StepperProps<T>) => {
    const { t } = useTranslation();
    const [step, setStep] = useState<number>(0);
    const [tempValue, setTempValue] = useState<Partial<T>>({ ...initialValue });
    const [value, setValue] = useState<Partial<T>>({ ...initialValue });
    const [errors, setErrors] = useState<Record<string, string[]>>({});

    const handleSubmit = useCallback((step: number, tempValue: Partial<T>) => {
        if (step === steps.length) {
            onSubmit(tempValue);
            return;
        }

        if (steps[step].onSubmit) {
            const [isValid, errors] = steps[step].onSubmit!(tempValue);

            if (!isValid) {
                setErrors(errors);
                return;
            }
        }

        setErrors({});
        setValue(tempValue);
        setStep(step + 1);
    }, [steps, onSubmit]);

    const handleStep = useCallback((step: number) => {
        setStep(step);
        setTempValue(value);
    }, [value]);

    const StepComponent = useMemo(() => {
        try {
            return steps[step].component
        } catch {
            return () => null;
        }
    }, [steps, step]);

    const ValidationComponent = useCallback(({ value }: ValidationComponentProps<T>) => (
        <div className="stepper-content-submit-content">
            {steps.map(s => {
                const Component = s.validationComponent;
                return (
                    <div key={s.key}>
                        <span>{s.icon}{i18n ? t(`${i18n}:${s.key}`) : s.label ?? s.key}</span>
                        <div>
                            <Component value={value} />
                        </div>
                    </div>
                )
            })}
        </div>
    ), [steps, i18n]);

    const stepsComponent = useMemo(() => {
        return steps.map((s, i) => (
            <Fragment key={s.key}>
                <div
                    className={`stepper-item ${i === step ? 'current' : ''} ${s.validation(value) ? 'done' : (i > 0 && steps[i - 1].validation(value)) || i === 0 ? 'next' : ''}`}
                    onClick={() => handleStep(i)}
                >
                    <div className="stepper-item-icon">
                        {s.icon}
                        <span>{i18n ? t(`${i18n}:${s.key}`) : s.label ?? s.key}</span>
                    </div>
                </div>
            </Fragment>
        ));
    }, [value, steps, step, i18n, handleStep]);

    useEffect(() => {
        let i = 0;
        for (i = 0; i < steps.length; i++) {
            if (!steps[i].validation(value)) {
                setStep(i);
                break;
            }
        }
    }, [value, steps]);

    useEffect(() => {
        setValue({ ...initialValue });
        setTempValue({ ...initialValue });
    }, [initialValue]);

    return (
        <div className="stepper">
            <div className="stepper-header">
                {stepsComponent}
                <div className={`stepper-item stepper-submit ${step === steps.length ? 'current' : ''} ${step === steps.length ? 'done' : ''}`}>
                    <div className="stepper-item-icon">
                        <CheckIcon />
                        <span>{t('actions:submit')}</span>
                    </div>
                </div>
            </div>
            {step < steps.length ? (
                <div className="stepper-content">
                    {!!steps[step].description && (
                        <div className="stepper-description">
                            <CircleHelpIcon />
                            <span>{i18n ? t(`${i18n}:${steps[step].description}`) : steps[step].description}</span>
                        </div>
                    )}
                    <StepComponent onChange={setTempValue} value={tempValue} errors={errors} />
                </div>
            ) : (
                <div className="stepper-content stepper-content-submit">
                    <ValidationComponent value={tempValue} />
                </div>
            )}
            <div className="stepper-actions">
                {step > 0 && <Button color="secondary" label={t('actions:previous')} onClick={() => handleStep(step - 1)} />}
                <Button color="primary" label={step === steps.length ? t('actions:submit') : t('actions:next')} onClick={() => handleSubmit(step, tempValue)} disabled={step < steps.length && !steps[step]?.validation(tempValue)} />
            </div>
        </div>
    )
}

export default Stepper;