import { useTranslation } from 'react-i18next';
import { Application } from '../../models/application';
import { ApplicationStatus } from '../../models/enums';
import { DataList } from '../../sg-react/data';
import { DataListFormProps, DataListSchema } from '../../sg-react/data/DataList';
import { Select, TextField } from '../../sg-react/form';
import { ValidationRule } from '../../sg-react/hooks/useForm';
import './index.scss';

const ApplicationForm = ({ attachInput }: DataListFormProps<Application>) => {
    return (
        <div id="application-form">
            <TextField small i18n="applications" label {...attachInput('name')} />
            <TextField small i18n="applications" label {...attachInput('frontUrl')} />
            <TextField small i18n="applications" label {...attachInput('apiUrl')} />
            <Select small i18n="applications" label items={Object.values(ApplicationStatus)} {...attachInput('status')} />
        </div>
    )
}

const LIST_SCHEMA: DataListSchema<Application> = {
    _id: {
        label: 'ID',
        width: '20%',
        options: {
            order: true,
        }
    },
    name: {
        label: 'Name',
        display: 'textinput',
        width: '20%',
        options: {
            order: true,
            startsWith: true,
            edit: true,
        }
    },
    frontUrl: {
        label: 'Interface',
        display: 'textinput',
        width: '20%',
        options: {
            order: true,
            startsWith: true,
            edit: true,
        }
    },
    apiUrl: {
        label: 'API',
        display: 'textinput',
        width: '20%',
        options: {
            order: true,
            startsWith: true,
            edit: true,
        }
    },
    status: {
        label: 'Status',
        display: 'select',
        values: Object.values(ApplicationStatus) as ApplicationStatus[],
        width: '20%',
        options: {
            order: true,
            in: true,
            edit: true,
        }
    },
}

const Applications = () => {
    const { t } = useTranslation();

    return (
        <DataList<Application>
            title={t('applications:title')}
            schema={LIST_SCHEMA}
            primaryKey="_id"
            endpoint="/applications"
            validation={{
                name: [{ rule: ValidationRule.Required }],
                status: [{ rule: ValidationRule.Required }],
            }}
            actions={{ view: true }}
            createForm={ApplicationForm}
            updateForm={ApplicationForm}
        />
    )
}

export default Applications;
