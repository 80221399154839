import { useEffect, useState } from "react";
import './index.scss';

export interface PieProps {
    values: { value: number, color: string }[];
    total?: number;
    withLabels?: boolean;
    withCenter?: boolean;
    size?: number;
}

const Pie = ({ values, total, size, withLabels, withCenter }: PieProps) => {
    const [background, setBackground] = useState<string>('');

    const getBackground = (_values: { value: number, color: string }[]): string => {
        const filteredValues = values.filter(v => v.value > 0);

        if (filteredValues.length === 0) return '#989ba2';
        if (filteredValues.length === 1) return filteredValues[0].color;

        const total = Object.values(filteredValues).reduce((t, v) => t + v.value, 0);

        const { gradient } = filteredValues
            .reduce((acc, v, i) => {
                const angle = Math.round(v.value * 360 / total);
                const endAngle = angle + acc.deg;
                const grad = i === 0
                    ? `${v.color} ${angle}deg, `
                    : i === filteredValues.length - 1
                        ? `${v.color} ${acc.deg}deg`
                        : `${v.color} ${acc.deg}deg ${endAngle}deg, `

                return { gradient: acc.gradient + grad, deg: acc.deg + angle };
            }, { gradient: '', deg: 0 });

        return `conic-gradient(${gradient})`;
    }

    useEffect(() => {
        setBackground(getBackground(values));
    }, [values]);

    return (
        <div
            className="pie"
            style={size !== undefined ? { background, width: size + 'px', height: size + 'px' } : { background }}
        >
            {!!withCenter && <div className="pie-center" />}
            {total !== undefined && <div className="pie-total">{total}</div>}
        </div>
    )
}

export default Pie;