import './index.scss';
import { Card } from '../../sg-react/ui';
import { useTranslation } from 'react-i18next';
import { RightIcon } from '../../sg-react/icons';
import { useEffect, useState } from 'react';
import { useRequest } from '../../sg-react/hooks';

interface AccessProps {

}

const Access = ({ }: AccessProps) => {
    const { t } = useTranslation();
    const request = useRequest();
    const [sauronUrl, setSauronUrl] = useState<string | null>(null);

    useEffect(() => {
        request.get<string>('/applications/url/sauron')
            .then((url) => setSauronUrl(url.startsWith('local') ? 'http://' + url : 'https://' + url))
            .catch(() => null);
    }, []);

    return (
        <div id="access">
            <div id="title">{t('path:access')}</div>
            <Card>
                <a href={sauronUrl ?? '#'}>{t('access:go_to')}<RightIcon /></a>
            </Card>
        </div>
    )
}

export default Access;