import { ReactNode } from "react";
import './index.scss';

interface FormGroupProps {
    children: ReactNode;
    label?: string;
}

const Group = ({ label, children }: FormGroupProps) => {

    return (
        <div className="group">
            <div className="group-label">
                <div />
                {!!label && <label>{label}</label>}
                <div />
            </div>
            <div className="group-content">
                {children}
            </div>
        </div>
    )
}

export default Group;