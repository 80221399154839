// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  gap: 8px;
}
.tabs > .tabs-header {
  display: flex;
  width: 100%;
  background-color: var(--theme-menu-background);
  border: var(--theme-menu-border) 1px solid;
}
.tabs > .tabs-header > * {
  flex: 1 1;
  cursor: pointer;
  padding: 4px 8px;
  color: var(--theme-menu-label-color);
  text-align: center;
}
.tabs > .tabs-header > *:hover {
  background-color: var(--theme-menu-background-hover);
}
.tabs > .tabs-header > *.active {
  color: var(--theme-menu-label-color-active);
}
.tabs > .tabs-header > *:not(:last-child) {
  border-right: var(--theme-menu-border) 1px solid;
}
.tabs > .tabs-content {
  width: 100%;
  display: flex;
  flex-shrink: 1;
}
.tabs > .tabs-content > * {
  width: 100%;
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Tabs/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,cAAA;EACA,QAAA;AACJ;AACI;EACI,aAAA;EACA,WAAA;EACA,8CAAA;EACA,0CAAA;AACR;AACQ;EACI,SAAA;EACA,eAAA;EACA,gBAAA;EACA,oCAAA;EACA,kBAAA;AACZ;AAAY;EACI,oDAAA;AAEhB;AACY;EACI,2CAAA;AAChB;AAEY;EACI,gDAAA;AAAhB;AAKI;EACI,WAAA;EACA,aAAA;EACA,cAAA;AAHR;AAKQ;EACI,WAAA;EACA,aAAA;AAHZ","sourcesContent":[".tabs {\n    display: flex;\n    flex-direction: column;\n    flex-shrink: 1;\n    gap: 8px;\n\n    & > .tabs-header {\n        display: flex;\n        width: 100%;\n        background-color: var(--theme-menu-background);\n        border: var(--theme-menu-border) 1px solid;\n\n        & > * {\n            flex: 1;\n            cursor: pointer;\n            padding: 4px 8px;\n            color: var(--theme-menu-label-color);\n            text-align: center;\n            &:hover {\n                background-color: var(--theme-menu-background-hover);\n            }\n\n            &.active {\n                color: var(--theme-menu-label-color-active);\n            }\n\n            &:not(:last-child) {\n                border-right: var(--theme-menu-border) 1px solid;\n            }\n        }\n    }\n\n    & > .tabs-content {\n        width: 100%;\n        display: flex;\n        flex-shrink: 1;\n\n        & > * {\n            width: 100%;\n            display: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
