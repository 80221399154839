import { ReactNode } from "react";
import './index.scss';

interface DetailProps {
    label: string;
    inline?: boolean;
    children: ReactNode;
}

const Detail = ({ label, inline, children }: DetailProps) => {

    return (
        <div className={`detail ${inline ? 'detail-inline' : ''}`}>
            <label>{label}</label>
            <div>{children}</div>
        </div>
    )
}

export default Detail;