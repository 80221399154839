export const ERR_STRING = "Err!";

export const COLORS = [
    //Red
    "#f44336",
    //Pink
    "#e81e63",
    //Purple
    "#9c27b0",
    //Deep Purple
    "#673ab7",
    //Indigo
    "#3f51b5",
    //Blue
    "#2196f3",
    //Light Blue
    "#03a9f4",
    //Cyan
    "#00bcd4",
    //Teal
    "#009688",
    //Green
    "#4caf50",
    //Light Green
    "#8bc34a",
    //Lime
    "#cddc39",
    //Yellow
    "#ffeb3b",
    //Amber
    "#ffc107",
    //Orange
    "#ff9800",
    //Deep Orange
    "#ff5722",
    //Brown
    "#795548",
    //Gray
    "#9e9e9e",
    //Blue Gray
    "#607d8b",
    //White
    "#ffffff",
];
