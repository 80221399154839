// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-small-backdrop {
  position: relative;
}
.modal-small-backdrop > .modal-small {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}
.modal-small-backdrop > .modal-small > .modal-small-close {
  width: 24px;
  height: 24px;
  position: absolute;
  cursor: pointer;
  top: 4px;
  right: 12px;
}
.modal-small-backdrop > .modal-small.modal-small-visible {
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/ModalSmall/index.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,kBAAA;AACR;AACQ;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,QAAA;EACA,WAAA;AACZ;AAEQ;EACI,mBAAA;AAAZ","sourcesContent":[".modal-small-backdrop {\n    position: relative;\n\n    & > .modal-small {\n        position: fixed;\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n        visibility: hidden;\n\n        & > .modal-small-close {\n            width: 24px;\n            height: 24px;\n            position: absolute;\n            cursor: pointer;\n            top: 4px;\n            right: 12px;\n        }\n\n        &.modal-small-visible {\n            visibility: visible;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
