import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useUiContext } from '../../sg-react/context';
import { TextField } from '../../sg-react/form';
import { useForm, useRequest } from '../../sg-react/hooks';
import { ValidationRule } from '../../sg-react/hooks/useForm';
import { LoginCredentials } from '../../sg-react/models';
import { Button, Card } from '../../sg-react/ui';
import './index.scss';

const RECOVERY_VALIDATION = {
    email: [
        { rule: ValidationRule.Required },
        { rule: ValidationRule.Email }
    ]
};

const Recovery = () => {
    const { t } = useTranslation();
    const { entity, validate, attachInput } = useForm<Omit<LoginCredentials, 'password'>>({ email: "" });
    const request = useRequest();
    const { setToastError } = useUiContext();
    const [isLoading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (e?: FormEvent): Promise<void> => {
        e?.preventDefault();
        if (isLoading || !validate(RECOVERY_VALIDATION)) return;

        setLoading(true);

        request.post('/auth/recovery', entity, { loader: true, successMessage: t('recovery:mail_sent') })
            .then(() => null)
            .catch((e) => {
                if (e?.response?.status === 429) {
                    setToastError(t('message:error.too_many_requests'));
                } else {
                    setToastError(t('message:error.server_error'));
                }
            })
            .finally(() => setLoading(false));
    }

    return (
        <Card title={t('recovery:title')} className="recovery">
            <form onSubmit={handleSubmit}>
                <TextField
                    {...attachInput('email')}
                    label={t('users:email')}
                    autoComplete
                />
                <div id="public-actions">
                    <Button
                        label={t('recovery:recover')}
                        color="accent"
                        type="submit"
                    />
                    <Link to='/'>{t('recovery:back')}</Link>
                </div>
            </form>
        </Card>
    )
}

export default Recovery;
