import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { SettingIcon, SquareFourIcon, SurveyIcon, TasksIcon, UserTieIcon, UsersIcon } from "../sg-react/icons";
import { Nav } from "../sg-react/ui";
import { Role } from "../sg-react/utils/enums";
import './index.scss';

const NAVIGATION = [
    { key: 'access', path: '', icon: <SquareFourIcon /> },
    { key: 'user-block' },
    { key: 'profile', path: 'profile', icon: <UserTieIcon /> },
    { key: 'settings', path: 'settings', icon: <SettingIcon /> },
    { key: 'administration-block', roles: [Role.SuperAdmin] },
    { key: 'users', path: 'users', icon: <UsersIcon />, roles: [Role.SuperAdmin] },
    { key: 'applications', path: 'applications', icon: <TasksIcon />, roles: [Role.SuperAdmin] },
    //{ key: 'surveys', path: 'surveys', icon: <SurveyIcon />, roles: [Role.SuperAdmin] }
]

const Private = () => {

    return (
        <Fragment>
            <Nav navigation={NAVIGATION} />
            <div id="content">
                <Outlet />
            </div>
        </Fragment>
    );

}

export default Private;