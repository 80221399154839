import { ReactNode } from 'react';
import './index.scss';

interface BubbleHeadProps {
    image?: string;
    className?: string;
    alt?: string;
    size?: 'small' | 'large' | 'larger';
    onClick?: () => void;
    icon?: ReactNode;
}

const BubbleHead = ({ image, alt, size = 'large', className, onClick, icon }: BubbleHeadProps) => {
    return (
        <div className={`bubble-head bubble-head-${size} ${className ?? ''}`} onClick={onClick}>
            <img src={image} alt={alt ?? ''} />
            {!!icon && <div className="bubble-head-icon">{icon}</div>}
        </div>
    )
}

export default BubbleHead;