export enum ToastType {
    Success = "SUCCESS",
    Warning = "WARNING",
    Error = "ERROR",
}

export enum Role {
    SuperAdmin = "SUPER_ADMIN",
    Admin = "ADMIN",
    User = "USER",
}
