import { ReactNode } from "react";
import './index.scss';

interface MouseOverWrapperProps {
    onMouseOver?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    children: ReactNode;
}

const MouseOverWrapper = ({ onMouseOver, onMouseEnter, onMouseLeave, children }: MouseOverWrapperProps) => {

    return (
        <div
            className="mouse-over-wrapper"
            onMouseOver={onMouseOver}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
        </div>
    )
}

export default MouseOverWrapper;