import { Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce, useRequest } from "../../hooks";
import { SortDownIcon, SortIcon, SortUpIcon } from "../../icons";
import { PaginatedResults, Pagination } from "../../models/pagination";
import { Dropdown, Menu, MenuDivider, MenuItem, Nothing } from "../../ui";
import Button, { ButtonColor } from "../../ui/Button";
import { getPrimaryKeysAsString } from "../../utils/objects";
import PaginationComponent from "../Pagination";
import "./index.scss";

export type DataCardListColumnFilters<T, U extends keyof T> = {
    type: 'search' | 'startsWith' | 'select' | 'date';
    values?: T[U] extends (Array<infer V> | undefined) ? V[] : T[U][];
    multiple?: boolean;
}

export interface DataCardListContentProps<T> {
    entity: T;
};

export interface DataCardListAction<T> {
    color: ButtonColor;
    label: string;
    icon?: ReactNode;
    onClick: (e: T) => Promise<void | boolean> | void | boolean;
}

export interface DataCardListGlobalAction {
    color: ButtonColor;
    label: string;
    icon?: ReactNode;
    onClick: () => Promise<void | boolean> | void | boolean;
}

export interface DataCardListLink<T> {
    label: string;
    icon?: ReactNode;
    onClick: (e: T) => void | Promise<void>;
}

export interface DataCardListProps<T> {
    sort?: { key: keyof T, label: string }[];
    withSearch?: boolean;
    card: (e: T) => ReactNode;
    primaryKey: keyof T | (keyof T)[];
    endpoint: string;
    actions?: DataCardListAction<T>[];
    globalActions?: DataCardListGlobalAction[];
    initialPagination?: Partial<Pagination>;
    additionalFilters?: { [k: string]: any };
}

const DataCardList = <T,>({
    sort,
    withSearch,
    card,
    primaryKey,
    endpoint,
    actions,
    globalActions,
    initialPagination,
    additionalFilters
}: DataCardListProps<T>) => {
    const { t } = useTranslation();
    const request = useRequest();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<T[]>([]);
    const [search, setSearch] = useState<string>('');
    const [pagination, setPagination] = useState<Pagination | null>();
    const ref = useRef<HTMLDivElement>(null);
    useDebounce(() => get(), 600, [search]);

    const get = useCallback(async (_pagination?: Partial<Pagination>) => {
        if (isLoading) return;

        const params = {
            ...(_pagination ?? pagination),
            search: !!search ? search : undefined,
            ...additionalFilters
        }
        setLoading(true);
        request.get<PaginatedResults<T>>(endpoint, { params, errorMessage: 'error.server_error', i18n: true, loader: true })
            .then((result) => {
                if (!Array.isArray(result?.data)) {
                    throw new Error();
                }
                setData(result.data);
                setPagination(result.pagination);
                if (ref.current) {
                    ref.current.scrollTo(0, 0);
                }
            })
            .catch(() => null)
            .finally(() => setLoading(false));
    }, [isLoading, pagination, endpoint, search, additionalFilters]);

    const handleParamsChange = useCallback((field: keyof Pagination, value: any) => {
        get({ ...pagination, [field]: value });
    }, [pagination, get]);

    const handleOrder = useCallback((key: keyof T, sortOrder: 1 | -1) => {
        get({
            ...pagination,
            sortBy: key as string,
            sortOrder,
        });
    }, [pagination, get]);

    const handleAction = useCallback(async (onClick: (e: T) => Promise<void | boolean> | void | boolean, e: T): Promise<void> => {
        const response = await onClick(e);

        if (response === true) {
            get();
        }
    }, []);

    const cards = useMemo(() => !data.length && !isLoading
        ? <Nothing />
        : data.map(entity => (
            <div
                className="data-card-list-card"
                key={getPrimaryKeysAsString(entity, primaryKey)}
            >
                {!!card && (
                    <div className="data-card-list-card-content">
                        {card(entity)}
                    </div>
                )}
                {!!actions?.length && (
                    <div className="data-card-list-card-actions">
                        {actions.map(action => (
                            <Button key={action.label} label={action.label} icon={action.icon} color={action.color} onClick={() => handleAction(action.onClick, entity)} />
                        ))}
                    </div>
                )}
            </div>
        ))
        , [data, primaryKey, card, actions, handleAction, isLoading]);

    const sortComponent = useMemo(() =>
        sort?.length ? (
            <Dropdown
                closeOnClick
                dropdown={
                    <Menu>
                        {sort.map((s, i) => (
                            <Fragment key={String(s.key)}>
                                <MenuItem icon={<SortUpIcon />} label={`${s.label} ${t('filters:ascending')}`} onClick={() => handleOrder(s.key, 1)} />
                                <MenuItem icon={<SortDownIcon />} label={`${s.label} ${t('filters:descending')}`} onClick={() => handleOrder(s.key, -1)} />
                                {i < sort.length && <MenuDivider />}
                            </Fragment>
                        ))}
                    </Menu>
                }
            >
                <div className="data-card-list-sort-button">
                    <span>{sort.find(s => s.key === pagination?.sortBy)?.label ?? t('filters:sort')}</span>
                    {!pagination?.sortOrder ? <SortIcon /> : pagination.sortOrder === 1 ? <SortUpIcon /> : <SortDownIcon />}
                </div>
            </Dropdown>
        ) : null
        , [sort, pagination, handleOrder]);

    const globalActionsComponents = useMemo(() =>
        !!globalActions?.length && globalActions.map((a) => (
            <Button color={a.color} key={a.label} label={a.label} icon={a.icon} onClick={a.onClick} />
        ))
        , [globalActions]);

    useEffect(() => {
        setData([]);
        get(initialPagination);
    }, [endpoint, additionalFilters]);

    return (
        <div className="data-card-list" ref={ref}>
            <div className="data-card-list-header">
                <div className="data-card-list-filters">
                    {!!withSearch && (
                        <input type="text" placeholder={t('filters:search')} value={search} onChange={(e) => setSearch(e.target.value)} />
                    )}
                </div>
                {!!sort?.length && (
                    <div className="data-card-list-actions">
                        {globalActionsComponents}
                        {sortComponent}
                    </div>
                )}
            </div>
            <div className="data-card-list-content">
                {cards}
            </div>
            <PaginationComponent data={data} pagination={pagination ?? undefined} onPageChange={(page) => handleParamsChange('page', page)} />
        </div >
    )
}

export default DataCardList;