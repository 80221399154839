import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../models/user';
import { useAuthContext } from '../../sg-react/context';
import { TextField } from '../../sg-react/form';
import ImageField from '../../sg-react/form/ImageField';
import { useForm, useRequest } from '../../sg-react/hooks';
import { ValidationRule } from '../../sg-react/hooks/useForm';
import { FloppyIcon } from '../../sg-react/icons';
import { Button } from '../../sg-react/ui';
import './index.scss';

const PROFILE_VALIDATION = {
    linkedIn: [{ rule: ValidationRule.Regex, compareTo: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm }],
    phone: [{ rule: ValidationRule.Regex, compareTo: /^\+?(\([0-9]{2,3}\))?[0-9 -]*$/ }],
    firstName: [{ rule: ValidationRule.Required }],
    lastName: [{ rule: ValidationRule.Required }],
};

const UserProfile = () => {
    const { currentUser, refreshUser } = useAuthContext();
    const { entity, validate, attachInput, setEntity } = useForm<User>({});
    const request = useRequest();
    const [isLoading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const handleSubmit = async (e?: FormEvent): Promise<void> => {
        e?.preventDefault();
        if (isLoading || !validate(PROFILE_VALIDATION)) return;

        setLoading(true)
        request.put<User>('/users/profile', entity, { i18n: true, successMessage: 'success.update', errorMessage: 'error.server_error' })
            .then(() => refreshUser())
            .catch(() => null)
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        setEntity(currentUser);
    }, []);

    return (
        <div id="profile">
            <div id="title">{t('path:profile')}</div>
            <form onSubmit={handleSubmit}>
                <TextField i18n="users" label {...attachInput('firstName')} />
                <TextField i18n="users" label {...attachInput('lastName')} />
                <ImageField i18n="users" label endpoint="/users/avatar" {...attachInput('avatar')} />
                <TextField i18n="users" label {...attachInput('linkedIn')} />
                <TextField i18n="users" label {...attachInput('phone')} />
                <TextField i18n="users" label {...attachInput('position')} />
                <TextField i18n="users" label {...attachInput('field')} />
                <div id="actions">
                    <Button
                        label={t('form:save')}
                        icon={<FloppyIcon />}
                        type="submit"
                        color="accent"
                        loading={isLoading}
                    />

                </div>
            </form>
        </div>
    )
}

export default UserProfile;