import { ReactNode } from 'react';
import './index.scss';
import { useOutsideClick } from '../../hooks';
import { CloseIcon } from '../../icons';

interface ModalProps {
    children?: ReactNode;
    onClose: () => void;
    visible?: boolean;
}

const ModalSmall = ({ children, onClose, visible }: ModalProps) => {
    const ref = useOutsideClick(onClose);

    return (
        <div className="modal-small-backdrop">
            <div className={`modal-small ${visible ? 'modal-small-visible' : ''}`} ref={ref}>
                <CloseIcon className="modal-small-close" onClick={onClose} />
                {children}
            </div>
        </div>
    )
}

export default ModalSmall;