import { Fragment, useCallback, useEffect } from "react";
import { DataListSchemaForState } from ".";
import { Checkbox } from "../../form";
import { useForm, useRequest } from "../../hooks";
import { EntityValidation } from "../../hooks/useForm";
import { FloppyIcon, ReloadIcon, ShowIcon } from "../../icons";
import Cell from "./Cell";

interface RowProps<T> {
    entity: T;
    selected?: boolean;
    columns: DataListSchemaForState<T>;
    validation?: EntityValidation<T>;
    endpoint: string;
    onView?: (e: T) => void;
    onSelect?: (e: T) => void;
}

const Row = <T,>({ entity: entityFromProps, selected, columns, endpoint, validation, onView, onSelect }: RowProps<T>) => {
    const { entity, validate, attachInput, hasChanged, reset } = useForm<T>(entityFromProps);
    const request = useRequest();

    const handleSubmit = useCallback(async () => {
        if (!validation && !validate(validation)) {
            return;
        }

        request.put<T>(endpoint, entity, {
            i18n: true,
            loader: true,
            successMessage: 'success.update',
            errorMessage: 'error.server_error'
        })
            .then(reset)
            .catch(() => null);
    }, [validate]);

    useEffect(() => {
        reset(entityFromProps);
    }, [entityFromProps]);

    return (
        <div className={`data-list-table-row ${hasChanged ? 'data-list-table-row-changed' : ''}`}>
            {!!onSelect && (
                <div className="data-list-table-row-select">
                    <Checkbox id="select" value={selected} onChange={(v) => onSelect(entityFromProps)} />
                </div>
            )}
            {Object.keys(columns).filter(key => !columns[key as keyof T]!.hidden).map((key) => {
                const columnSchema = columns[key as keyof T]!;
                return (
                    <Cell
                        key={key}
                        entity={entity}
                        column={columnSchema!}
                        {...attachInput(key as keyof T)}
                    />
                )
            })}
            {(hasChanged || onView) && (
                <div className="data-list-table-row-actions">
                    {!!hasChanged && (
                        <Fragment>
                            <button type="button" className="action-refresh" onClick={() => reset()}><ReloadIcon /></button>
                            <button type="button" className="action-save" onClick={handleSubmit}><FloppyIcon /></button>
                        </Fragment>
                    )}
                    {!!onView && <button type="button" className="action-view" onClick={() => onView(entity as T)}><ShowIcon /></button>}
                </div>
            )}
        </div>
    )
}

export default Row;