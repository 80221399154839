import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import CheckboxList from '../../form/CheckboxList';
import './index.scss';

interface MenuListProps<T> {
    label?: string;
    items: T[],
    value?: T[];
    onChange: (v?: T[]) => void;
}

export const MenuList = <T,>({ value, label, items, onChange }: MenuListProps<T>) => (
    <div className="menu-list">
        {!!label && <label>{label}</label>}
        <CheckboxList<T[]> id="menu-list" value={value} items={items} onChange={(v) => onChange(v)} multiple />
    </div>
);

interface MenuTextFieldProps {
    placeholder?: string;
    label?: string;
    value?: string;
    onChange: (v: string) => void;
}

export const MenuTextField = ({ value, label, placeholder, onChange }: MenuTextFieldProps) => (
    <div className="menu-text-field">
        {!!label && <label>{label}</label>}
        <input
            type="text"
            onChange={(e) => onChange(e.target.value)}
            value={value ?? ''}
            placeholder={placeholder ?? ''}
            onClick={(e) => e.stopPropagation()}
        />
    </div>
);

interface MenuItemProps {
    label: string;
    icon?: ReactNode;
    onClick?: () => void;
    to?: string;
    target?: string;
}

export const MenuItem = ({ label, icon, onClick, to, target }: MenuItemProps) => !to
    ? (
        <div className="menu-item" onMouseDown={onClick}>
            {!!icon && <div className="menu-item-icon">{icon}</div>}
            <div className="menu-item-label">{label}</div>
        </div>
    )
    : !target ? (
        <Link className="menu-item" to={to}>
            {!!icon && <div className="menu-item-icon">{icon}</div>}
            <div className="menu-item-label">{label}</div>
        </Link>
    ) : (
        <a className="menu-item" href={to} target={target}>
            {!!icon && <div className="menu-item-icon">{icon}</div>}
            <div className="menu-item-label">{label}</div>
        </a>
    );


export const MenuDivider = () => <div className="menu-divider" />;

const Menu = ({ children }: { children: ReactNode }) => {

    return (
        <div className="menu">
            {children}
        </div>
    )
}
export default Menu;