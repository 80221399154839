import { useRouteError } from "react-router-dom";
import './index.scss';

const RouteError = () => {
    const error = useRouteError();

    return (
        <div id="error-page">
            <h1>Page not found</h1>
            <p>Please check the address, go back, or contact the administrator if something went wrong</p>
        </div>
    );
}

export default RouteError;