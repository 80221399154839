// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.presets {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
}
.presets > .button {
  padding: 0 4px;
  background-color: transparent;
}
.presets .filters-modal {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  gap: 12px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Presets/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;AACJ;AACI;EACI,cAAA;EACA,6BAAA;AACR;AAEI;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,SAAA;AAAR","sourcesContent":[".presets {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    font-size: 18px;\n\n    & > .button {\n        padding: 0 4px;\n        background-color: transparent;\n    }\n\n    & .filters-modal {\n        display: flex;\n        flex-direction: column;\n        min-width: 600px;\n        gap: 12px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
