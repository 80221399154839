import React, { useEffect, useState, useRef } from "react";
import { ToastType } from "../../utils/enums";
import { ErrorIcon, SuccessIcon } from "../../icons";
import './index.scss';

export type ToastData = {
    type: ToastType;
    message: string;
    data?: any;
}

interface ToastProps {
    toast: ToastData | null;
    onClose: () => void;
}

const Toast = ({
    toast,
    onClose
}: ToastProps) => {
    const timeout = useRef<NodeJS.Timeout | null>(null)
    const [currentToast, setCurrentToast] = useState<ToastData | null>(null);

    const clear = () => timeout.current ? clearTimeout(timeout.current) : undefined;
    const start = (limit = 5000) => {
        if (timeout?.current) {
            clear();
        }
        timeout.current = setTimeout(onClose, limit)
    }

    const handleClose = (e: React.MouseEvent<HTMLElement>, error?: any) => {
        e.stopPropagation();
        clear();
        onClose();
    }

    useEffect(() => {
        if (toast) {
            setCurrentToast(toast);
            clear();
            start();
        }
        return clear;
    }, [toast]);

    return (
        <div className={`toast ${toast ? 'toast-active' : ''} toast-${currentToast?.type.toLowerCase()}`} onMouseOver={clear} onMouseLeave={() => start(1000)} onClick={(e) => handleClose(e)}>
            <div>
                {currentToast?.type === ToastType.Error && <ErrorIcon />}
                {currentToast?.type === ToastType.Success && <SuccessIcon />}
                <span>{currentToast?.message}</span>
            </div>
        </div>
    );
}

export default Toast;