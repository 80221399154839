import { useCallback, useEffect, useState } from 'react';
import { PaginatedResults } from '../../models/pagination';
import AxiosInstance from '../../services/Axios';
import { Dropdown } from '../../ui';
import { toggleInArray } from '../../utils/objects';
import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import './index.scss';

type SelectListItem = string | number | { key: string | number, label?: string };

export interface SelectProps extends FormPropsInterface<string | number | (string | number)[]> {
    items?: SelectListItem[],
    maxHeight?: string;
    multiple?: boolean,
    endpoint?: string,
}

const Select = ({
    value,
    items,
    endpoint,
    disabled,
    multiple,
    maxHeight = '300px',
    onChange,
    ...rest
}: SelectProps) => {
    const [listItems, setListItems] = useState<(string | number | SelectListItem)[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);

    const handleGetItems = async () => {
        if (!endpoint || isLoading) return;

        setLoading(true);
        try {
            const response = await AxiosInstance.get<PaginatedResults<SelectListItem> | SelectListItem[]>(endpoint, { params: { perPage: -1 } });
            setListItems(Array.isArray(response.data) ? response.data : response.data.data);
        } catch {
            setListItems([]);
        } finally {
            setLoading(false);
        }
    }

    const handleChange = (_id: string | number) => {
        if (disabled) return;
        if (!multiple) {
            onChange(_id);
            return;
        }
        if (!Array.isArray(value) && value !== undefined) {
            if (value === _id) {
                onChange([_id]);
            } else {
                onChange([value, _id]);
            }
            return;
        }

        const _value = toggleInArray(value ?? [], _id, (v1, v2) => v1 === v2);
        onChange(_value);
    }

    const getId = useCallback((i: string | number | SelectListItem): string | number =>
        typeof i === 'string' || typeof i === 'number' ? i : i.key
        , []);

    const getLabel = useCallback((i: string | number | SelectListItem): string | number =>
        typeof i === 'string' || typeof i === 'number' ? i : i.label ?? i.key
        , []);

    const isSelected = useCallback((i: string | number | SelectListItem): boolean =>
        !!value && ((Array.isArray(value) && value.includes(getId(i))) || value === getId(i))
        , [getId, value]);

    useEffect(() => {
        if (endpoint) {
            handleGetItems();
        } else if (items?.length) {
            setListItems(items);
        }
    }, [endpoint, items]);

    return (
        <InputWrapper type="select" {...rest}>
            <Dropdown
                maxHeight={maxHeight}
                closeOnClick
                dropdown={
                    listItems.map((i) => (
                        <div onClick={() => handleChange(getId(i))}
                            key={getId(i)}
                            className={`select-item ${isSelected(i) ? 'selected' : ''}`}
                        >
                            {getLabel(i)}
                        </div>
                    ))
                }
            >
                {listItems.filter(isSelected).map(getLabel).join(', ')}
            </Dropdown>
        </InputWrapper>
    )
}

export default Select;
