import Axios, {
    AxiosError,
    AxiosRequestConfig,
    AxiosResponse,
    isAxiosError,
} from "axios";
import { isIsoDateString } from "../utils/date";

const handleDates = (body: any) => {
    if (body === null || body === undefined) return body;

    if (Array.isArray(body)) {
        for (const element of body) {
            handleDates(element);
        }
    } else {
        for (const key of Object.keys(body)) {
            const value = body[key];

            if (isIsoDateString(value)) body[key] = new Date(value);
            else if (typeof value === "object") handleDates(value);
        }
    }
    return body;
};

const AxiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_API_URL ?? "http://localhost:3005",
    timeout: 60000,
    headers: { "Content-Type": "application/json" },
    transformResponse: [
        ...(!Axios.defaults.transformResponse
            ? []
            : Array.isArray(Axios.defaults.transformResponse)
            ? Axios.defaults.transformResponse
            : [Axios.defaults.transformResponse]),
        handleDates,
    ],
});

AxiosInstance.interceptors.request.use(
    (config) => {
        config.headers["Authorization"] =
            "Bearer " + sessionStorage.getItem("sg-token");
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

AxiosInstance.interceptors.response.use(
    (response) => {
        //handleDates(response.data);
        return response;
    },
    async (error: AxiosError | Error): Promise<AxiosError | Error> => {
        if (isAxiosError(error)) {
            const { message } = error;
            const { method, url } = error.config as AxiosRequestConfig;
            const { statusText, status } =
                (error.response as AxiosResponse) ?? {};
        }

        return Promise.reject(error);
    }
);

export default AxiosInstance;
