// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=switch] .input-field {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  padding: 2px !important;
  border-radius: 4px;
  background-color: var(--theme-input-background);
}
.input[input-type=switch] .input-field > * {
  display: flex;
  flex-basis: 1;
  padding: 2px 6px;
  border-radius: 3px;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--theme-input-font-color);
}
.input[input-type=switch] .input-field > *:hover {
  color: var(--theme-input-font-color-focus);
}
.input[input-type=switch] .input-field > *.switch-item-selected {
  background-color: var(--theme-color-success);
  color: var(--theme-color-white);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/Switch/index.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,+CAAA;AAAR;AAEQ;EACI,aAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,oCAAA;AAAZ;AAEY;EACI,0CAAA;AAAhB;AAGY;EACI,4CAAA;EACA,+BAAA;AADhB","sourcesContent":[".input[input-type=\"switch\"] {\n    & .input-field {\n        display: grid;\n        grid-auto-columns: 1fr;\n        grid-auto-flow: column;\n        padding: 2px !important;\n        border-radius: 4px;\n        background-color: var(--theme-input-background);\n\n        & > * {\n            display: flex;\n            flex-basis: 1;\n            padding: 2px 6px;\n            border-radius: 3px;\n            height: 100%;\n            box-sizing: border-box;\n            align-items: center;\n            justify-content: center;\n            cursor: pointer;\n            color: var(--theme-input-font-color);\n\n            &:hover {\n                color: var(--theme-input-font-color-focus);\n            }\n\n            &.switch-item-selected {\n                background-color: var(--theme-color-success);\n                color: var(--theme-color-white);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
