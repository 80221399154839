import { useTranslation } from 'react-i18next';
import { Survey } from '../../models/survey';
import { DataList } from '../../sg-react/data';
import { DataListSchema } from '../../sg-react/data/DataList';
import { ValidationRule } from '../../sg-react/hooks/useForm';
import './index.scss';

const LIST_SCHEMA: DataListSchema<Survey> = {
    _id: {
        label: 'ID',
        width: '20%',
        options: {
            order: true,
        }
    },
    name: {
        label: 'Name',
        display: 'textinput',
        width: '20%',
        options: {
            order: true,
            startsWith: true,
            edit: true,
        }
    },
}

const Surveys = () => {
    const { t } = useTranslation();

    return (
        <DataList<Survey>
            title={t('surveys:title')}
            schema={LIST_SCHEMA}
            primaryKey="_id"
            endpoint="/surveys"
            validation={{
                name: [{ rule: ValidationRule.Required }],
            }}
            actions={{ view: true }}
        />
    )
}

export default Surveys;